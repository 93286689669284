const USER_TYPES = {
  RESET_STATE: "RESET_STATE",

  OPEN_LOGIN_MODAL: "OPEN_LOGIN_MODAL",
  CLOSE_LOGIN_MODAL: "CLOSE_LOGIN_MODAL",

  UPDATE_SELECT_CATEGORY_ITEMS_BEGIN: "UPDATE_SELECT_CATEGORY_ITEMS_BEGIN",
  UPDATE_SELECT_CATEGORY_ITEMS_SUCCESS: "UPDATE_SELECT_CATEGORY_ITEMS_SUCCESS",
  UPDATE_SELECT_CATEGORY_ITEMS_FAILURE: "UPDATE_SELECT_CATEGORY_ITEMS_FAILURE",

  GET_ONBOARDING_INTERESTS_BEGIN: "GET_ONBOARDING_INTERESTS_BEGIN",
  GET_ONBOARDING_INTERESTS_SUCCESS: "GET_ONBOARDING_INTERESTS_SUCCESS",
  GET_ONBOARDING_INTERESTS_FAILURE: "GET_ONBOARDING_INTERESTS_FAILURE",

  FETCH_PROFILE_PUBLIC_OUTFIT_BEGIN: "FETCH_PROFILE_PUBLIC_OUTFIT_BEGIN",
  FETCH_PROFILE_PUBLIC_OUTFIT_SUCCESS: "FETCH_PROFILE_PUBLIC_OUTFIT_SUCCESS",
  FETCH_PROFILE_PUBLIC_OUTFIT_FAILURE: "FETCH_PROFILE_PUBLIC_OUTFIT_FAILURE",

  FETCH_CART: "FETCH_CART",
  OPEN_CART_POPUP: "OPEN_CART_POPUP",
  CLOSE_CART_POPUP: "CLOSE_CART_POPUP",

  OPEN_WARDROBE_ROOM_POPUP: "OPEN_WARDROBE_ROOM_POPUP",
  CLOSE_WARDROBE_ROOM_POPUP: "CLOSE_WARDROBE_ROOM_POPUP",

  FETCH_USER_PROFILE_BEGIN: "FETCH_USER_PROFILE_BEGIN",
  FETCH_USER_PROFILE_SUCCESS: "FETCH_USER_PROFILE_SUCCESS",
  FETCH_USER_PROFILE_FAILURE: "FETCH_USER_PROFILE_FAILURE",

  ADD_PRODUCT_BEGIN: "ADD_PRODUCT_BEGIN",
  ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
  ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",

  FETCH_CART_BEGIN: "FETCH_CART_BEGIN",
  FETCH_CART_SUCCESS: "FETCH_CART_SUCCESS",
  FETCH_CART_FAILURE: "FETCH_CART_FAILURE",

  FETCH_ALL_PRODUCT_BEGIN: "FETCH_ALL_PRODUCT_BEGIN",
  FETCH_ALL_PRODUCT_SUCCESS: "FETCH_ALL_PRODUCT_SUCCESS",
  FETCH_ALL_PRODUCT_FAILURE: "FETCH_ALL_PRODUCT_FAILURE",

  FETCH_SAVED_PRODUCT_BEGIN: "FETCH_SAVED_PRODUCT_BEGIN",
  FETCH_SAVED_PRODUCT_SUCCESS: "FETCH_SAVED_PRODUCT_SUCCESS",
  FETCH_SAVED_PRODUCT_FAILURE: "FETCH_SAVED_PRODUCT_FAILURE",

  //FETCH_PRODUCT_PURCHASE_HISTORY
  FETCH_SAVED_PRODUCT_PURCHASE_HISTORY_BEGIN:
    "FETCH_SAVED_PRODUCT_PURCHASE_HISTORY_BEGIN",
  FETCH_SAVED_PRODUCT_PURCHASE_HISTORY_SUCCESS:
    "FETCH_SAVED_PRODUCT_PURCHASE_HISTORY_SUCCESS",
  FETCH_SAVED_PRODUCT_PURCHASE_HISTORY_FAILURE:
    "FETCH_SAVED_PRODUCT_PURCHASE_HISTORY_FAILURE",

  POST_SAVED_PRODUCT_PURCHASE_HISTORY_BEGIN:
    "POST_SAVED_PRODUCT_PURCHASE_HISTORY_BEGIN",
  POST_SAVED_PRODUCT_PURCHASE_HISTORY_SUCCESS:
    "POST_SAVED_PRODUCT_PURCHASE_HISTORY_SUCCESS",
  POST_SAVED_PRODUCT_PURCHASE_HISTORY_FAILURE:
    "POST_SAVED_PRODUCT_PURCHASE_HISTORY_FAILURE",

  POST_REMOVE_SAVED_PRODUCT_PURCHASE_HISTORY_BEGIN:
    "POST_REMOVE_SAVED_PRODUCT_PURCHASE_HISTORY_BEGIN",
  POST_REMOVE_SAVED_PRODUCT_PURCHASE_HISTORY_SUCCESS:
    "POST_REMOVE_SAVED_PRODUCT_PURCHASE_HISTORY_SUCCESS",
  POST_REMOVE_SAVED_PRODUCT_PURCHASE_HISTORY_FAILURE:
    "POST_REMOVE_SAVED_PRODUCT_PURCHASE_HISTORY_FAILURE",

  CATEGORY_ITEMS_SELECTED_BEGIN: "CATEGORY_ITEMS_SELECTED_BEGIN",
  CATEGORY_ITEMS_SELECTED_SUCCESS: "CATEGORY_ITEMS_SELECTED_SUCCESS",
  CATEGORY_ITEMS_SELECTED_FAILURE: "CATEGORY_ITEMS_SELECTED_FAILURE",

  GET_YOUR_LISTS_BEGIN: "GET_YOUR_LISTS_BEGIN",
  GET_YOUR_LISTS_SUCCESS: "GET_YOUR_LISTS_SUCCESS",
  GET_YOUR_LISTS_FAILURE: "GET_YOUR_LISTS_FAILURE",
  GET_YOUR_LISTS_RESET: "GET_YOUR_LISTS_RESET",

  CREATE_YOUR_LISTS_BEGIN: "CREATE_YOUR_LISTS_BEGIN",
  CREATE_YOUR_LISTS_SUCCESS: "CREATE_YOUR_LISTS_SUCCESS",
  CREATE_YOUR_LISTS_FAILURE: "CREATE_YOUR_LISTS_FAILURE",

  UPDATE_YOUR_LISTS_BEGIN: "UPDATE_YOUR_LISTS_BEGIN",
  UPDATE_YOUR_LISTS_SUCCESS: "UPDATE_YOUR_LISTS_SUCCESS",
  UPDATE_YOUR_LISTS_FAILURE: "UPDATE_YOUR_LISTS_FAILURE",

  UPDATE_LIST_ACCESS_BEGIN: "UPDATE_LIST_ACCESS_BEGIN",
  UPDATE_LIST_ACCESS_SUCCESS: "UPDATE_LIST_ACCESS_SUCCESS",
  UPDATE_LIST_ACCESS_FAILURE: "UPDATE_LIST_ACCESS_FAILURE",

  DELETE_YOUR_LISTS_BEGIN: " DELETE_YOUR_LISTS_BEGIN",
  DELETE_YOUR_LISTS_SUCCESS: " DELETE_YOUR_LISTS_SUCCESS",
  DELETE_YOUR_LISTS_FAILURE: " DELETE_YOUR_LISTS_FAILURE",

  DUPLICATE_LIST_BEGIN: " DUPLICATE_LIST_BEGIN",
  DUPLICATE_LIST_SUCCESS: " DUPLICATE_LIST_SUCCESS",
  DUPLICATE_LIST_FAILURE: " DUPLICATE_LIST_FAILURE",

  LIKE_LIST_BEGIN: " LIKE_LIST_BEGIN",
  LIKE_LIST_SUCCESS: " LIKE_LIST_SUCCESS",
  LIKE_LIST_FAILURE: " LIKE_LIST_FAILURE",

  GET_ITEM_DETAILS_BEGIN: "GET_ITEM_DETAILS_BEGIN",
  GET_ITEM_DETAILS_SUCCESS: "GET_ITEM_DETAILS_SUCCESS",
  GET_ITEM_DETAILS_FAILURE: "GET_ITEM_DETAILS_FAILURE",

  // GET User Earnings
  GET_USER_EARNING_BEGIN: "GET_USER_EARNING_BEGIN",
  GET_USER_EARNING_SUCCESS: "GET_USER_EARNING_SUCCESS",
  GET_USER_EARNING_FAILURE: "GET_USER_EARNING_FAILURE",

  // GET User Referral
  GET_USER_REFERRAL_BEGIN: "GET_USER_REFERRAL_BEGIN",
  GET_USER_REFERRAL_SUCCESS: "GET_USER_REFERRAL_SUCCESS",
  GET_USER_REFERRAL_FAILURE: "GET_USER_REFERRAL_FAILURE",

  // Products
  ADD_PRODUCT_TO_ANOTHER_LIST_BEGIN: "ADD_PRODUCT_TO_ANOTHER_LIST_BEGIN",
  ADD_PRODUCT_TO_ANOTHER_LIST_SUCCESS: "ADD_PRODUCT_TO_ANOTHER_LIST_SUCCESS",
  ADD_PRODUCT_TO_ANOTHER_LIST_FAILURE: "ADD_PRODUCT_TO_ANOTHER_LIST_FAILURE",

  LIKE_PRODUCT_BEGIN: "LIKE_PRODUCT_BEGIN",
  LIKE_PRODUCT_SUCCESS: "LIKE_PRODUCT_SUCCESS",
  LIKE_PRODUCT_FAILURE: "LIKE_PRODUCT_FAILURE",

  DELETE_PRODUCT_BEGIN: "DELETE_PRODUCT_BEGIN",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",

  GET_LIST_BEGIN: "GET_USER_LIST_BEGIN",
  GET_LIST_SUCCESS: "GET_USER_LIST_SUCCESS",
  GET_LIST_FAILURE: "GET_USER_LIST_FAILURE",

  GET_DETAILS_BEGIN: "GET_USER_DETAILS_BEGIN",
  GET_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_DETAILS_FAILURE: "GET_USER_DETAILS_FAILURE",

  GET_USER_GOALS_BEGIN: "GET_USER_GOALS_BEGIN",
  GET_USER_GOALS_SUCCESS: "GET_USER_GOALS_SUCCESS",
  GET_USER_GOALS_FAILURE: "GET_USER_GOALS_FAILURE",

  CREATE_USER_REPORT_BEGIN: "CREATE_USER_REPORT_BEGIN",
  CREATE_USER_REPORT_SUCCESS: "CREATE_USER_REPORT_SUCCESS",
  CREATE_USER_REPORT_FAILURE: "CREATE_USER_REPORT_FAILURE",

  CREATE_USER_GOAL_REPORT_BEGIN: "CREATE_USER_GOAL_REPORT_BEGIN",
  CREATE_USER_GOAL_REPORT_SUCCESS: "CREATE_USER_GOAL_REPORT_SUCCESS",
  CREATE_USER_GOAL_REPORT_FAILURE: "CREATE_USER_GOAL_REPORT_FAILURE",

  GET_USER_TIMELINE_BEGIN: "GET_USER_TIMELINE_BEGIN",
  GET_USER_TIMELINE_SUCCESS: "GET_USER_TIMELINE_SUCCESS",
  GET_USER_TIMELINE_FAILURE: "GET_USER_TIMELINE_FAILURE",

  UPDATE_USER_BEGIN: "UPDATE_USER_BEGIN",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  DELETE_USER_BEGIN: "DELETE_USER_BEGIN",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",

  GET_GENERATED_REPORTS_LIST_BEGIN: "GET_GENERATED_REPORTS_LIST_BEGIN",
  GET_GENERATED_REPORTS_LIST_SUCCESS: "GET_GENERATED_REPORTS_LIST_SUCCESS",
  GET_GENERATED_REPORTS_LIST_FAILURE: "GET_GENERATED_REPORTS_LIST_FAILURE",

  GET_REPORTS_SIGNED_URL_BEGIN: "GET_REPORTS_SIGNED_URL_BEGIN",
  GET_REPORTS_SIGNED_URL_SUCCESS: "GET_REPORTS_SIGNED_URL_SUCCESS",
  GET_REPORTS_SIGNED_URL_FAILURE: "GET_REPORTS_SIGNED_URL_FAILURE",

  GET_NOTIFICATION_SETTING_BEGIN: "GET_NOTIFICATION_SETTING_BEGIN",
  GET_NOTIFICATION_SETTING_SUCCESS: "GET_NOTIFICATION_SETTING_SUCCESS",
  GET_NOTIFICATION_SETTING_FAILURE: "GET_NOTIFICATION_SETTING_FAILURE",

  POST_NOTIFICATION_SETTING_BEGIN: "POST_NOTIFICATION_SETTING_BEGIN",
  POST_NOTIFICATION_SETTING_SUCCESS: "POST_NOTIFICATION_SETTING_SUCCESS",
  POST_NOTIFICATION_SETTING_FAILURE: "POST_NOTIFICATION_SETTING_FAILURE",

  // Wardrobe
  POST_WARDROBE_OUTFIT_BEGIN: "POST_WARDROBE_OUTFIT_BEGIN",
  POST_WARDROBE_OUTFIT_SUCCESS: "POST_WARDROBE_OUTFIT_SUCCESS",
  POST_WARDROBE_OUTFIT_FAILURE: "POST_WARDROBE_OUTFIT_FAILURE",

  UPDATE_ITEM_WARDROBE_OUTFIT_BEGIN: "UPDATE_ITEM_WARDROBE_OUTFIT_BEGIN",
  UPDATE_ITEM_WARDROBE_OUTFIT_SUCCESS: "UPDATE_ITEM_WARDROBE_OUTFIT_SUCCESS",
  UPDATE_ITEM_WARDROBE_OUTFIT_FAILURE: "UPDATE_ITEM_WARDROBE_OUTFIT_FAILURE",

  POST_IMAGE_WARDROBE_OUTFIT_BEGIN: "POST_IMAGE_WARDROBE_OUTFIT_BEGIN",
  POST_IMAGE_WARDROBE_OUTFIT_SUCCESS: "POST_IMAGE_WARDROBE_OUTFIT_SUCCESS",
  POST_IMAGE_WARDROBE_OUTFIT_FAILURE: "POST_IMAGE_WARDROBE_OUTFIT_FAILURE",

  DELETE_WARDROBE_OUTFIT_BEGIN: "DELETE_WARDROBE_OUTFIT_BEGIN",
  DELETE_WARDROBE_OUTFIT_SUCCESS: "DELETE_WARDROBE_OUTFIT_SUCCESS",
  DELETE_WARDROBE_OUTFIT_FAILURE: "DELETE_WARDROBE_OUTFIT_FAILURE",

  DELETE_WARDROBE_ITEM_BYID_OUTFIT_BEGIN:
    "DELETE_WARDROBE_ITEM_BYID_OUTFIT_BEGIN",
  DELETE_WARDROBE_ITEM_BYID_OUTFIT_SUCCESS:
    "DELETE_WARDROBE_ITEM_BYID_OUTFIT_SUCCESS",
  DELETE_WARDROBE_ITEM_BYID_OUTFIT_FAILURE:
    "DELETE_WARDROBE_ITEM_BYID_OUTFIT_FAILURE",

  GET_WARDROBE_OUTFIT_BEGIN: "GET_WARDROBE_OUTFIT_BEGIN",
  GET_WARDROBE_OUTFIT_SUCCESS: "GET_WARDROBE_OUTFIT_SUCCESS",
  GET_WARDROBE_OUTFIT_FAILURE: "GET_WARDROBE_OUTFIT_FAILURE",

  GET_PRODUCT_TAGS_BEGIN: "GET_PRODUCT_TAGS_BEGIN",
  GET_PRODUCT_TAGS_SUCCESS: "GET_PRODUCT_TAGS_SUCCESS",
  GET_PRODUCT_TAGS_FAILURE: "GET_PRODUCT_TAGS_FAILURE",

  // EXPLORE
  GET_EXPLORE_TAGS_BEGIN: "GET_EXPLORE_TAGS_BEGIN",
  GET_EXPLORE_TAGS_SUCCESS: "GET_EXPLORE_TAGS_SUCCESS",
  GET_EXPLORE_TAGS_FAILURE: "GET_EXPLORE_TAGS_FAILURE",

  // FETCH_TOP_BRANDS_USER
  FETCH_TOP_BRANDS_USER_BEGIN: "FETCH_TOP_BRANDS_USER_BEGIN",
  FETCH_TOP_BRANDS_USER_SUCCESS: "FETCH_TOP_BRANDS_USER_SUCCESS",
  FETCH_TOP_BRANDS_USER_FAILURE: "FETCH_TOP_BRANDS_USER_FAILURE",

  // FETCH_TOP_BRANDS_GUEST
  FETCH_TOP_BRANDS_GUEST_BEGIN: "FETCH_TOP_BRANDS_GUEST_BEGIN",
  FETCH_TOP_BRANDS_GUEST_SUCCESS: "FETCH_TOP_BRANDS_GUEST_SUCCESS",
  FETCH_TOP_BRANDS_GUEST_FAILURE: "FETCH_TOP_BRANDS_GUEST_FAILURE",

  // FETCH_FOR_YOU_USER
  FETCH_FOR_YOU_USER_BEGIN: "FETCH_FOR_YOU_USER_BEGIN",
  FETCH_FOR_YOU_USER_SUCCESS: "FETCH_FOR_YOU_USER_SUCCESS",
  FETCH_FOR_YOU_USER_FAILURE: "FETCH_FOR_YOU_USER_FAILURE",

  // FETCH_FOR_YOU_GUEST
  FETCH_FOR_YOU_GUEST_BEGIN: "FETCH_FOR_YOU_GUEST_BEGIN",
  FETCH_FOR_YOU_GUEST_SUCCESS: "FETCH_FOR_YOU_GUEST_SUCCESS",
  FETCH_FOR_YOU_GUEST_FAILURE: "FETCH_FOR_YOU_GUEST_FAILURE",

  //GET_FOLLOW_USER_PUBLIC_LIST

  GET_FOLLOW_USER_PUBLIC_LIST_BEGIN: "GET_FOLLOW_USER_PUBLIC_LIST_BEGIN",
  GET_FOLLOW_USER_PUBLIC_LIST_SUCCESS: "GET_FOLLOW_USER_PUBLIC_LIST_SUCCESS",
  GET_FOLLOW_USER_PUBLIC_LIST_FAILURE: "GET_FOLLOW_USER_PUBLIC_LIST_FAILURE",

  GET_DETAIL_BY_ID_WARDROBE_OUTFIT_BEGIN:
    "GET_DETAIL_BY_ID_WARDROBE_OUTFIT_BEGIN",
  GET_DETAIL_BY_ID_WARDROBE_OUTFIT_SUCCESS:
    "GET_DETAIL_BY_ID_WARDROBE_OUTFIT_SUCCESS",
  GET_DETAIL_BY_ID_WARDROBE_OUTFIT_FAILURE:
    "GET_DETAIL_BY_ID_WARDROBE_OUTFIT_FAILURE",

  SET_FILTERS: "SET_FILTERS",
  RESET_FILTERS: "RESET_FILTERS",
};

export default USER_TYPES;
