const Constants = {
  MEMBER: {
    SUPER_ADMIN: "Super Admin",
  },
  ADMIN_ROLES: {
    SECTIONS: {
      TEST: "test", // update sections list
    },
    PERMISSIONS: {
      CREATE: "CREATE",
      READ: "READ",
      UPDATE: "UPDATE",
      DELETE: "DELETE",
      LIST: "LIST",
    },
  },
  DATE_FORMAT: {
    dddDDMMM: "ddd, DD MMM",
    dddMMDD: "ddd MMM  DD",
  },
  LENGTH_OF_OTP: 6,
  COUNTRY_CODE: "US",
  OTP_PLACEHOLDER: ["-", "-", "-", "-", "-", "-"],
  RANDOM_COLORS: ["#B1BED3", "#DDB19B", "#9CE2A3", "#EBD793", "#BB96D3"],
  ROLE_ATTRIBUTES: {
    DASHBOARD: "dashboard",
    EXPLORE: "explore",
    WARDROBE: "wardrobe",
    YOURLISTS: "your-lists",
    NOTIFICATION: "notifications",
    EDITPROFILE: "edit-Profile",
    ORDERS: "orders",
    SECURITY: "security",
    NOTIFICATIONS: "notifications",
    REFERRAL: "referral",
  },
  ROLE_ACCESS_TYPES: {
    CREATE: "create",
    READ: "read",
    UPDATE: "update",
    DELETE: "delete",
    DETAIL: "details",
  },
};

export default Constants;
