import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseModal1 } from "../../assets/images/closeModal1.svg";
import empty from "../../assets/images/empty.png";
import Delete from "../../assets/images/your-lists/Delete.svg";
import {
  performDeleteProduct,
  performFetchCartProduct,
} from "../../redux/actionCreators/userCreators";
import { closeCartPopup } from "../../redux/actions/userActions";
import useCartLengthMonitor from "../../utils/CustomHooks/CartLengthMonitor";
import SpinLoader from "../SpinLoader/SpinLoader";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getPrice } from "../../utils";
import { performAddProduct } from "../../redux/actionCreators/userCreators";
// import { performFetchCart } from "../../redux/actionCreators/userCreators";

const PAGE = 1;
const PER_PAGE = 100;
const Cart = ({ fetchCartProduct, deleteProduct, guest, fetching, addProduct }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const cartopen = useSelector((state) => state.user?.isCartOpen);

  const dispatch = useDispatch();
  const [savedCart, setSavedCart] = useState(null);
  const [loading, setLoading] = useState(true);

  const onClosePop = () => {
    dispatch(closeCartPopup());
  };

  // handle Fetch Saved CART Product
  const handleFetchCartProduct = useCallback(
    async (page, limit) => {
      setLoading(true);
      const result = await fetchCartProduct({ page, limit });
      if (result) {
        setSavedCart(result?.docs);
        setLoading(false);
      }
    },
    [fetchCartProduct]
  );

  useEffect(() => {
    if (guest === false) {
      handleFetchCartProduct(PAGE, PER_PAGE);
    }
  }, [guest, handleFetchCartProduct]);

  // Delete Cart Item
  const handleDeleteProduct = async (data) => {
    const newData = {
      type: "Cart",
      productIds: [data],
    };
    const result = await deleteProduct(newData);
    if (result?.status) {
      handleFetchCartProduct(PAGE, PER_PAGE);
      history.replace({
        pathname,
        state: {
          isCartListUpdated: true,
          productId: data,
        },
      });
    }
  };

  //Total Of Cart
  const total = savedCart
    ?.reduce((total, item) => {
      const price = Number(
        item?.product
          ? item?.product?.sanitizePrice?.replace(/[^0-9.-]+/g, "")
          : item?.sanitizePrice?.replace(/[^0-9.-]+/g, "")
      );
      return total + price;
    }, 0)
    .toFixed(2);

  // Checkout Button
  const handleCheckout = () => {
    savedCart?.forEach((item) => {
      window.open(
        item?.product && item?.product?.affiliateUrl
          ? item?.product?.affiliateUrl
          : item?.product?.url,
        "_blank"
      );
    });
  };

  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: "40%",
      flexShrink: 0,
    },
    drawerPaper: {
      width: "400px",
      background: "#000000",
      borderRadius: "16px 0px 0px 16px",
      padding: "24px 24px 16px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }));
  const classes = useStyles();

  const handleDrop = (e) => {
    e.preventDefault();
    const productId = e.dataTransfer.getData('application/json');
    if (productId) {
      handleAddProduct(JSON.parse(productId));
    }
    if (e.target.id === 'drag-to-cart-button') {
      e.target.style.backgroundColor = ''; // Remove highlight
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    if (e.target.id === 'drag-to-cart-button') {
      e.target.style.backgroundColor = 'rgba(229, 177, 94, 0.5)'; // Highlight button
    }
  };
  
  const handleDragLeave = (e) => {
    if (e.target.id === 'drag-to-cart-button') {
      e.target.style.backgroundColor = ''; // Remove highlight
    }
  };
  

  const handleAddProduct = async (data) => {
    let newData = { type: `Cart`, isNotified: true, productId: data.id };
    await addProduct(newData);
    handleFetchCartProduct(PAGE, PER_PAGE);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={cartopen}
      onClose={onClosePop}
      BackdropProps={{
        invisible: true,
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div 
        className="add-to-cart-drawer-container"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div
          className="cart-item-total align-items-center  w-100 justify-content-between"
          onClick={() => onClosePop()}
        >
          <div className="d-flex align-items-center gap-2">
            <p className="">Cart</p>
            {savedCart && savedCart?.length >= 1 && (
              <div className="count">
                <p>{savedCart?.length}</p>
              </div>
            )}
          </div>
          <button
            onClick={() => onClosePop()}
            className="border-0 me-n4 text-white "
            style={{
              background: "none",
            }}
          >
            <CloseModal1
              style={{ width: "40px", height: "40px" }}
              className="p-2"
            />
            {/* <img src={closeModal1} alt="close-the-modal" /> */}
          </button>
        </div>
        <>
          <div className="cart-item-container">
            {loading ? (
              <Container className="d-flex justify-content-center align-items-center loader-container mt-5">
                <SpinLoader color="#e5b15e" />
              </Container>
            ) : (
              <>
                {savedCart?.map((item, index) => {
                  return (
                    <div className="cart-item-card" key={index}>
                      <div className="cart-item">
                        <img
                          src={
                            item?.product ? item?.product?.image : item?.image
                          }
                          alt="image2"
                          className="cart-item-image object-fit-contain"
                        />
                        <div className="cart-item-content-container">
                          <div className="cart-item-content">
                            <div className="text-content">
                              <div className="title-content">
                                <p className="title fs-500">
                                  {item.product
                                    ? item.product?.title
                                    : item?.title}
                                </p>
                                <p className="desciption">
                                  {item.product
                                    ? item?.product?.description
                                    : item?.description}
                                </p>
                              </div>
                              <p className="price fs-500 text-nowrap">
                                {item?.product?.sanitizePrice &&
                                  getPrice(
                                    item?.product?.currency,
                                    item?.product?.sanitizePrice
                                  )}
                              </p>
                            </div>

                            <div className="cart-item-option-container">
                              <div className="cart-item-size-container"></div>
                              <img
                                src={Delete}
                                alt="Delete"
                                onClick={() =>
                                  fetching
                                    ? null
                                    : handleDeleteProduct(item?.product?._id)
                                }
                                className="rounded background-color-2"
                                style={{
                                  cursor: fetching ? "default" : "pointer",
                                  width: "24px",
                                  height: "24px",
                                  padding: "4px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="cart-item-bottom me-n2"
                        onClick={() => {
                          window.open(
                            item?.product && item?.product?.affiliateUrl
                              ? item?.product?.affiliateUrl
                              : item?.product?.url,
                            "_blank"
                          );
                        }}
                      >
                        <p className="text-truncate-1">
                          Buy on{" "}
                          {item?.product ? item?.product?.brandName : item?.brandName}
                        </p>
                      </div>
                    </div>
                  );
                })}
                {!savedCart?.length && (
                  <div className="mt-5">
                    <div className="empty-cart-container mt-5">
                      <img src={empty} alt="image2" />
                      <p>You haven’t added any item to cart yet.</p>
                      <p>Drag items here to get started!</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      </div>
      {savedCart && savedCart?.length >= 1 && (
        <div className="subtotal-container justify-content-center mt-3 cart">
          <div className="checkout-button ">
            <button 
              className="btn cart "
              id="drag-to-cart-button"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
            >
              <span>Drag to cart</span>
            </button>
          </div>
          <div className="subtotal mt-4 pt-3">
            <p>Subtotal ({savedCart?.length} items)</p>
            <span>$ {total}</span>
          </div>
        </div>
      )}
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  fetching: state.user.fetching,
  guest: state.auth.guest,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCartProduct: (payload) => dispatch(performFetchCartProduct(payload)),
  deleteProduct: (payload) => dispatch(performDeleteProduct(payload)),
  addProduct: (payload) => dispatch(performAddProduct(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
