const ACCOUNT_TYPES = {
  RESET_STATE: "RESET_STATE",

  UPDATE_PROFILE_BEGIN: "UPDATE_PROFILE_BEGIN",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",

  UPDATE_PASSWORD_BEGIN: "UPDATE_PASSWORD_BEGIN",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",

  CHANGE_PROFILE_PASSWORD_BEGIN: "CHANGE_PROFILE_PASSWORD_BEGIN",
  CHANGE_PROFILE_PASSWORD_SUCCESS: "CHANGE_PROFILE_PASSWORD_SUCCESS",
  CHANGE_PROFILE_PASSWORD_FAILURE: "CHANGE_PROFILE_PASSWORD_FAILURE",

  //FOLLOW_UNFOLLOW_USER
  FOLLOW_UNFOLLOW_USER: "FOLLOW_UNFOLLOW_USER",

  //SEARCH_USER_BY_ID
  SEARCH_USER_BY_ID_BEGIN: "SEARCH_USER_BY_ID_BEGIN",
  SEARCH_USER_BY_ID_SUCCESS: "SEARCH_USER_BY_ID_SUCCESS",
  SEARCH_USER_BY_ID_FAILURE: "SEARCH_USER_BY_ID_FAILURE",

  //SEARCH_USER_BY_NAME
  SEARCH_USER_BY_NAME: "SEARCH_USER_BY_NAME",
};

export default ACCOUNT_TYPES;
