import React from "react";
import { MdOutlineNavigateBefore } from "react-icons/md";
import { ReactComponent as Back } from "../../assets/images/back.svg";

const PreviousArrow = ({
  onClick,
  currentSlide,
  customStyle,
  buttonSize = 40,
  gradientStyle,
  isHome,
}) => {
  return (
    <>
      {currentSlide > 0 && (
        <>
          <div
            className="slider-corner-shadow position-absolute "
            style={{ top: 0, left: 0, ...gradientStyle }}
          />
          <span
            className="custom-arrow prev"
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "42%",
              left: "-3%",

              color: "#fff",
              zIndex: 2,
              ...customStyle,
            }}
            onClick={onClick}
          >
            {isHome ? <Back /> : <MdOutlineNavigateBefore size={buttonSize} />}
          </span>
        </>
      )}
    </>
  );
};

export default PreviousArrow;
