import ACCOUNT_TYPES from "../types/accountTypes";

export const resetState = () => ({
  type: ACCOUNT_TYPES.RESET_STATE,
});

export const updateProfileBegin = () => ({
  type: ACCOUNT_TYPES.UPDATE_PROFILE_BEGIN,
});

export const updateProfileSuccess = () => ({
  type: ACCOUNT_TYPES.UPDATE_PROFILE_SUCCESS,
});

export const updateProfileFailure = (error) => ({
  type: ACCOUNT_TYPES.UPDATE_PROFILE_FAILURE,
  payload: { error },
});

export const updatePasswordBegin = () => ({
  type: ACCOUNT_TYPES.UPDATE_PASSWORD_BEGIN,
});

export const updatePasswordSuccess = () => ({
  type: ACCOUNT_TYPES.UPDATE_PASSWORD_SUCCESS,
});

export const updatePasswordFailure = (error) => ({
  type: ACCOUNT_TYPES.UPDATE_PASSWORD_FAILURE,
  payload: { error },
});

//Change_PROFILE_Password
export const changeProfilePasswordBegin = () => ({
  type: ACCOUNT_TYPES.CHANGE_PROFILE_PASSWORD_BEGIN,
});

export const changeProfilePasswordSuccess = () => ({
  type: ACCOUNT_TYPES.CHANGE_PROFILE_PASSWORD_SUCCESS,
});

export const changeProfilePasswordFailure = (error) => ({
  type: ACCOUNT_TYPES.CHANGE_PROFILE_PASSWORD_FAILURE,
  payload: { error },
});

//SEARCH_USER_BY_ID
export const FetchProfileByIDBegin = () => ({
  type: ACCOUNT_TYPES.SEARCH_USER_BY_ID_BEGIN,
});

export const FetchProfileByIDSuccess = (data) => ({
  type: ACCOUNT_TYPES.SEARCH_USER_BY_ID_SUCCESS,
  payload: data,
});

export const FetchProfileByIDFailure = (error) => ({
  type: ACCOUNT_TYPES.SEARCH_USER_BY_ID_FAILURE,
  payload: { error },
});

//SEARCH_USER_BY_NAME
export const searchUserByName = (data) => ({
  type: ACCOUNT_TYPES.SEARCH_USER_BY_NAME,
  payload: data,
});

//FOLLOW_UNFOLLOW_USER
export const followUnfollowUser = (data) => ({
  type: ACCOUNT_TYPES.FOLLOW_UNFOLLOW_USER,
  payload: data,
});
