import React from "react";
import { MdOutlineNavigateNext } from "react-icons/md";
import { ReactComponent as Next } from "../../assets/images/next.svg";

const NextArrow = ({
  onClick,
  currentSlide,
  slideCount,
  slidesToShow,
  customStyle,
  buttonSize = 40,
  gradientStyle,
  isHome,
}) => {
  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <>
          <div
            className="slider-corner-shadow position-absolute "
            style={{ top: 0, right: 0, ...gradientStyle }}
          />

          <div
            className="custom-arrow next"
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "42%",
              right: "-1%",

              color: "#fff",
              zIndex: 2,
              ...customStyle,
            }}
            onClick={onClick}
          >
            {isHome ? <Next /> : <MdOutlineNavigateNext size={buttonSize} />}
          </div>
        </>
      )}
    </>
  );
};

export default NextArrow;
