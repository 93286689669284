import USER_TYPES from "../types/userTypes";

export const resetState = () => ({
  type: USER_TYPES.RESET_STATE,
});

// Login Modal for Guest
export const openLoginModal = () => ({
  type: USER_TYPES.OPEN_LOGIN_MODAL,
});
export const closeLoginModal = () => ({
  type: USER_TYPES.CLOSE_LOGIN_MODAL,
});

//getOnboardingInterests
export const getOnboardingInterestsBegin = () => ({
  type: USER_TYPES.GET_ONBOARDING_INTERESTS_BEGIN,
});

export const getOnboardingInterestsSuccess = (data) => ({
  type: USER_TYPES.GET_ONBOARDING_INTERESTS_SUCCESS,
  payload: data,
});

export const getOnboardingInterestsFailure = (error) => ({
  type: USER_TYPES.GET_ONBOARDING_INTERESTS_FAILURE,
  payload: error,
});

//FETCH_ALL_PRODUCT
export const fetchAllProductBegin = () => ({
  type: USER_TYPES.FETCH_ALL_PRODUCT_BEGIN,
});

export const fetchAllProductSuccess = (data) => ({
  type: USER_TYPES.FETCH_ALL_PRODUCT_SUCCESS,
  payload: data,
});

export const fetchAllProductFailure = (error) => ({
  type: USER_TYPES.FETCH_ALL_PRODUCT_FAILURE,
  payload: error,
});

//FETCH_SAVED_PRODUCT
export const fetchSavedProductBegin = () => ({
  type: USER_TYPES.FETCH_SAVED_PRODUCT_BEGIN,
});

export const fetchSavedProductSuccess = (data) => ({
  type: USER_TYPES.FETCH_SAVED_PRODUCT_SUCCESS,
  payload: data,
});

export const fetchSavedProductFailure = (error) => ({
  type: USER_TYPES.FETCH_SAVED_PRODUCT_FAILURE,
  payload: error,
});

//FETCH_PRODUCT_PURCHASE_HISTORY
export const fetchSavedProductPurchaseHistoryBegin = () => ({
  type: USER_TYPES.FETCH_SAVED_PRODUCT_PURCHASE_HISTORY_BEGIN,
});

export const fetchSavedProductPurchaseHistorySuccess = (data) => ({
  type: USER_TYPES.FETCH_SAVED_PRODUCT_PURCHASE_HISTORY_SUCCESS,
  payload: data,
});

export const fetchSavedProductPurchaseHistoryFailure = (error) => ({
  type: USER_TYPES.FETCH_SAVED_PRODUCT_PURCHASE_HISTORY_FAILURE,
  payload: error,
});

export const PostSavedProductPurchaseHistoryBegin = () => ({
  type: USER_TYPES.POST_SAVED_PRODUCT_PURCHASE_HISTORY_BEGIN,
});

export const PostSavedProductPurchaseHistorySuccess = (data) => ({
  type: USER_TYPES.POST_SAVED_PRODUCT_PURCHASE_HISTORY_SUCCESS,
  payload: data,
});

export const PostSavedProductPurchaseHistoryFailure = (error) => ({
  type: USER_TYPES.POST_SAVED_PRODUCT_PURCHASE_HISTORY_FAILURE,
  payload: error,
});

export const PostRemoveSavedProductPurchaseHistoryBegin = () => ({  
  type: USER_TYPES.POST_REMOVE_SAVED_PRODUCT_PURCHASE_HISTORY_BEGIN,
});

export const PostRemoveSavedProductPurchaseHistorySuccess = (data) => ({
  type: USER_TYPES.POST_REMOVE_SAVED_PRODUCT_PURCHASE_HISTORY_SUCCESS,
  payload: data,
});

export const PostRemoveSavedProductPurchaseHistoryFailure = (error) => ({
  type: USER_TYPES.POST_REMOVE_SAVED_PRODUCT_PURCHASE_HISTORY_FAILURE,
  payload: error,
});

//FETCH_CART
export const fetchCart = (data) => ({
  type: USER_TYPES.FETCH_CART,
  payload: data,
});
export const fetchCartBegin = () => ({
  type: USER_TYPES.FETCH_CART_BEGIN,
});

export const fetchCartSuccess = (data) => ({
  type: USER_TYPES.FETCH_CART_SUCCESS,
  payload: data,
});

export const fetchCartFailure = (error) => ({
  type: USER_TYPES.FETCH_CART_FAILURE,
  payload: error,
});

export const openCartPopup = () => ({
  type: USER_TYPES.OPEN_CART_POPUP,
});
export const closeCartPopup = () => ({
  type: USER_TYPES.CLOSE_CART_POPUP,
});

//WARDROBE
export const createWardrobeBegin = () => ({
  type: USER_TYPES.POST_WARDROBE_OUTFIT_BEGIN,
});

export const createWardrobeSuccess = (data) => ({
  type: USER_TYPES.POST_WARDROBE_OUTFIT_SUCCESS,
  payload: data,
});

export const createWardrobeFailure = (error) => ({
  type: USER_TYPES.POST_WARDROBE_OUTFIT_FAILURE,
  payload: error,
});
export const uploadImageWardrobeBegin = () => ({
  type: USER_TYPES.POST_IMAGE_WARDROBE_OUTFIT_BEGIN,
});

export const uploadImageWardrobeSuccess = (data) => ({
  type: USER_TYPES.POST_IMAGE_WARDROBE_OUTFIT_SUCCESS,
  payload: data,
});

export const uploadImageWardrobeFailure = (error) => ({
  type: USER_TYPES.POST_IMAGE_WARDROBE_OUTFIT_FAILURE,
  payload: error,
});

export const updateItemWardrobeBegin = () => ({
  type: USER_TYPES.UPDATE_ITEM_WARDROBE_OUTFIT_BEGIN,
});

export const updateItemWardrobeSuccess = (data) => ({
  type: USER_TYPES.UPDATE_ITEM_WARDROBE_OUTFIT_SUCCESS,
  payload: data,
});

export const updateItemWardrobeFailure = (error) => ({
  type: USER_TYPES.UPDATE_ITEM_WARDROBE_OUTFIT_FAILURE,
  payload: error,
});
export const fetchWardrobeBegin = () => ({
  type: USER_TYPES.GET_WARDROBE_OUTFIT_BEGIN,
});

export const fetchWardrobeSuccess = (data) => ({
  type: USER_TYPES.GET_WARDROBE_OUTFIT_SUCCESS,
  payload: data,
});

export const fetchWardrobeFailure = (error) => ({
  type: USER_TYPES.GET_WARDROBE_OUTFIT_FAILURE,
  payload: error,
});
export const fetchWardrobeByIdBegin = () => ({
  type: USER_TYPES.GET_DETAIL_BY_ID_WARDROBE_OUTFIT_BEGIN,
});

export const fetchWardrobeByIdSuccess = (data) => ({
  type: USER_TYPES.GET_DETAIL_BY_ID_WARDROBE_OUTFIT_SUCCESS,
  payload: data,
});

export const fetchWardrobeByIdFailure = (error) => ({
  type: USER_TYPES.GET_DETAIL_BY_ID_WARDROBE_OUTFIT_FAILURE,
  payload: error,
});
export const fetchProductTagsBegin = () => ({
  type: USER_TYPES.GET_PRODUCT_TAGS_BEGIN,
});

export const fetchProductTagsSuccess = (data) => ({
  type: USER_TYPES.GET_PRODUCT_TAGS_SUCCESS,
  payload: data,
});

export const fetchProductTagsFailure = (error) => ({
  type: USER_TYPES.GET_PRODUCT_TAGS_FAILURE,
  payload: error,
});

export const deleteWardrobeBegin = () => ({
  type: USER_TYPES.DELETE_WARDROBE_OUTFIT_BEGIN,
});

export const deleteWardrobeSuccess = (data) => ({
  type: USER_TYPES.DELETE_WARDROBE_OUTFIT_SUCCESS,
  payload: data,
});

export const deleteWardrobeFailure = (error) => ({
  type: USER_TYPES.DELETE_WARDROBE_OUTFIT_FAILURE,
  payload: error,
});
export const deleteWardrobeItemByidBegin = () => ({
  type: USER_TYPES.DELETE_WARDROBE_ITEM_BYID_OUTFIT_BEGIN,
});

export const deleteWardrobeItemByidSuccess = (data) => ({
  type: USER_TYPES.DELETE_WARDROBE_ITEM_BYID_OUTFIT_SUCCESS,
  payload: data,
});

export const deleteWardrobeItemByidFailure = (error) => ({
  type: USER_TYPES.DELETE_WARDROBE_ITEM_BYID_OUTFIT_FAILURE,
  payload: error,
});

export const openWardrobeRoomDrawer = () => ({
  type: USER_TYPES.OPEN_WARDROBE_ROOM_POPUP,
});
export const closeWardrobeRoomDrawer = () => ({
  type: USER_TYPES.CLOSE_WARDROBE_ROOM_POPUP,
});

// Add New Product
export const addProductBegin = () => ({
  type: USER_TYPES.ADD_PRODUCT_BEGIN,
});

export const addProductSuccess = (data) => ({
  type: USER_TYPES.ADD_PRODUCT_SUCCESS,
  payload: data,
});

export const addProductFailure = (error) => ({
  type: USER_TYPES.ADD_PRODUCT_FAILURE,
  payload: error,
});

//Fetch Selected Category Items lists
export const getCategoryItemsSelectedBegin = () => ({
  type: USER_TYPES.CATEGORY_ITEMS_SELECTED_BEGIN,
});

export const getCategoryItemsSelectedSuccess = (data) => ({
  type: USER_TYPES.CATEGORY_ITEMS_SELECTED_SUCCESS,
  payload: data,
});

export const getCategoryItemsSelectedFailure = (error) => ({
  type: USER_TYPES.CATEGORY_ITEMS_SELECTED_FAILURE,
  payload: error,
});

//FETCH_USER_PROFILE
export const getFetchUserProfileBegin = () => ({
  type: USER_TYPES.FETCH_USER_PROFILE_BEGIN,
});

export const getFetchUserProfileSuccess = (data) => ({
  type: USER_TYPES.FETCH_USER_PROFILE_SUCCESS,
  payload: data,
});

export const getFetchUserProfileFailure = (error) => ({
  type: USER_TYPES.FETCH_USER_PROFILE_FAILURE,
  payload: error,
});

//Select Category Items for User
export const updateSelectCategoryItemsBegin = () => ({
  type: USER_TYPES.UPDATE_SELECT_CATEGORY_ITEMS_BEGIN,
});
export const updateSelectCategoryItemsSuccess = (data) => ({
  type: USER_TYPES.UPDATE_SELECT_CATEGORY_ITEMS_SUCCESS,
  payload: data,
});
export const updateSelectCategoryItemsFailure = (error) => ({
  type: USER_TYPES.UPDATE_SELECT_CATEGORY_ITEMS_FAILURE,
  payload: error,
});

//getGetYourLists
export const getYourListsBegin = () => ({
  type: USER_TYPES.GET_YOUR_LISTS_BEGIN,
});
export const getYourListsSuccess = (data) => ({
  type: USER_TYPES.GET_YOUR_LISTS_SUCCESS,
  payload: data,
});
export const getYourListsFailure = (error) => ({
  type: USER_TYPES.GET_YOUR_LISTS_FAILURE,
  payload: error,
});

//CreateYourLists
export const CreateYourListsBegin = () => ({
  type: USER_TYPES.CREATE_YOUR_LISTS_BEGIN,
});
export const CreateYourListsSuccess = (data) => ({
  type: USER_TYPES.CREATE_YOUR_LISTS_SUCCESS,
  payload: data,
});

export const CreateYourListsFailure = (error) => ({
  type: USER_TYPES.CREATE_YOUR_LISTS_FAILURE,
  payload: error,
});

//Update Your Lists
export const updateYourListBegin = () => ({
  type: USER_TYPES.UPDATE_YOUR_LISTS_BEGIN,
});
export const updateYourListSuccess = (data) => ({
  type: USER_TYPES.UPDATE_YOUR_LISTS_SUCCESS,
  payload: data,
});
export const updateYourListFailure = (error) => ({
  type: USER_TYPES.UPDATE_YOUR_LISTS_FAILURE,
  payload: error,
});

//Update List Access
export const updateListAccessBegin = () => ({
  type: USER_TYPES.UPDATE_LIST_ACCESS_BEGIN,
});
export const updateListAccessSuccess = (data) => ({
  type: USER_TYPES.UPDATE_LIST_ACCESS_SUCCESS,
  payload: data,
});
export const updateListAccessFailure = (error) => ({
  type: USER_TYPES.UPDATE_LIST_ACCESS_FAILURE,
  payload: error,
});

//deleteYourLists
export const DeleteYourListBegin = () => ({
  type: USER_TYPES.DELETE_YOUR_LISTS_BEGIN,
});
export const DeleteYourListSuccess = (data) => ({
  type: USER_TYPES.DELETE_YOUR_LISTS_SUCCESS,
  payload: data,
});
export const DeleteYourListFailure = (error) => ({
  type: USER_TYPES.DELETE_YOUR_LISTS_FAILURE,
  payload: error,
});

//Duplicate YourLists
export const duplicateListBegin = () => ({
  type: USER_TYPES.DUPLICATE_LIST_BEGIN,
});
export const duplicateListSuccess = (data) => ({
  type: USER_TYPES.DUPLICATE_LIST_SUCCESS,
  payload: data,
});
export const duplicateListFailure = (error) => ({
  type: USER_TYPES.DUPLICATE_LIST_FAILURE,
  payload: error,
});

//Like Your Lists
export const likeListBegin = () => ({
  type: USER_TYPES.LIKE_LIST_BEGIN,
});
export const likeListSuccess = (data) => ({
  type: USER_TYPES.LIKE_LIST_SUCCESS,
  payload: data,
});
export const likeListFailure = (error) => ({
  type: USER_TYPES.LIKE_LIST_FAILURE,
  payload: error,
});

// GET_ITEM_DETAILS
export const getItemDetailsBegin = () => ({
  type: USER_TYPES.GET_ITEM_DETAILS_BEGIN,
});
export const getItemDetailsSuccess = (data) => ({
  type: USER_TYPES.GET_ITEM_DETAILS_SUCCESS,
  payload: data,
});
export const getItemDetailsFailure = (error) => ({
  type: USER_TYPES.GET_ITEM_DETAILS_FAILURE,
  payload: error,
});

// ADD_PRODUCT_TO_ANOTHER_LIST
export const addProductToAnotherListBegin = () => ({
  type: USER_TYPES.ADD_PRODUCT_TO_ANOTHER_LIST_BEGIN,
});
export const addProductToAnotherListSuccess = (data) => ({
  type: USER_TYPES.ADD_PRODUCT_TO_ANOTHER_LIST_SUCCESS,
  payload: data,
});
export const addProductToAnotherListFailure = (error) => ({
  type: USER_TYPES.ADD_PRODUCT_TO_ANOTHER_LIST_FAILURE,
  payload: error,
});

// LIKE_PRODUCT
export const likeProductBegin = () => ({
  type: USER_TYPES.LIKE_PRODUCT_BEGIN,
});
export const likeProductSuccess = (data) => ({
  type: USER_TYPES.LIKE_PRODUCT_SUCCESS,
  payload: data,
});
export const likeProductFailure = (error) => ({
  type: USER_TYPES.LIKE_PRODUCT_FAILURE,
  payload: error,
});

// DELETE_PRODUCT
export const deleteProductBegin = () => ({
  type: USER_TYPES.DELETE_PRODUCT_BEGIN,
});
export const deleteProductSuccess = (data) => ({
  type: USER_TYPES.DELETE_PRODUCT_SUCCESS,
  payload: data,
});
export const deleteProductFailure = (error) => ({
  type: USER_TYPES.DELETE_PRODUCT_FAILURE,
  payload: error,
});

// GET_USER_EARNING
export const getUserEarningBegin = () => ({
  type: USER_TYPES.GET_USER_EARNING_BEGIN,
});
export const getUserEarningSuccess = (data) => ({
  type: USER_TYPES.GET_USER_EARNING_SUCCESS,
  payload: data,
});
export const getUserEarningFailure = (error) => ({
  type: USER_TYPES.GET_USER_EARNING_FAILURE,
  payload: error,
});

// GET_USER_REFERRAL
export const getUserReferralBegin = () => ({
  type: USER_TYPES.GET_USER_REFERRAL_BEGIN,
});
export const getUserReferralSuccess = (data) => ({
  type: USER_TYPES.GET_USER_REFERRAL_SUCCESS,
  payload: data,
});
export const getUserReferralFailure = (error) => ({
  type: USER_TYPES.GET_USER_REFERRAL_FAILURE,
  payload: error,
});

// GET_NOTIFICATION_SETTING

export const getNotificationSettingBegin = () => ({
  type: USER_TYPES.GET_NOTIFICATION_SETTING_BEGIN,
});
export const getItemNotificationSettingSuccess = (data) => ({
  type: USER_TYPES.GET_NOTIFICATION_SETTING_SUCCESS,
});
export const getItemNotificationSettingFailure = (error) => ({
  type: USER_TYPES.GET_NOTIFICATION_SETTING_FAILURE,
  payload: error,
});

// UPDATE_NOTIFICATION_SETTING

export const postNotificationSettingBegin = () => ({
  type: USER_TYPES.POST_NOTIFICATION_SETTING_BEGIN,
});
export const postItemNotificationSettingSuccess = (data) => ({
  type: USER_TYPES.POST_NOTIFICATION_SETTING_SUCCESS,
});
export const postItemNotificationSettingFailure = (error) => ({
  type: USER_TYPES.POST_NOTIFICATION_SETTING_FAILURE,
  payload: error,
});

// EXPLORE TAGS(for explore all section)
export const getExploreTagsBegin = () => ({
  type: USER_TYPES.GET_EXPLORE_TAGS_BEGIN,
});

export const getExploreTagsSuccess = (data) => ({
  type: USER_TYPES.GET_EXPLORE_TAGS_SUCCESS,
  payload: data,
});

export const getExploreTagsFailure = (error) => ({
  type: USER_TYPES.GET_EXPLORE_TAGS_FAILURE,
  payload: error,
});

// FETCH_TOP_BRANDS_USER
export const fetchTopBrandsUserBegin = () => ({
  type: USER_TYPES.FETCH_TOP_BRANDS_USER_BEGIN,
});

export const fetchTopBrandsUserSuccess = (data) => ({
  type: USER_TYPES.FETCH_TOP_BRANDS_USER_SUCCESS,
  payload: data,
});

export const fetchTopBrandsUserFailure = (error) => ({
  type: USER_TYPES.FETCH_TOP_BRANDS_USER_FAILURE,
  payload: error,
});

// FETCH_TOP_BRANDS_GUEST
export const fetchTopBrandsGuestBegin = () => ({
  type: USER_TYPES.FETCH_TOP_BRANDS_GUEST_BEGIN,
});

export const fetchTopBrandsGuestSuccess = (data) => ({
  type: USER_TYPES.FETCH_TOP_BRANDS_GUEST_SUCCESS,
  payload: data,
});

export const fetchTopBrandsGuestFailure = (error) => ({
  type: USER_TYPES.FETCH_TOP_BRANDS_GUEST_FAILURE,
  payload: error,
});

// FETCH_FOR_YOU_USER
export const fetchForYouUserBegin = () => ({
  type: USER_TYPES.FETCH_FOR_YOU_USER_BEGIN,
});

export const fetchForYouUserSuccess = (data) => ({
  type: USER_TYPES.FETCH_FOR_YOU_USER_SUCCESS,
  payload: data,
});

export const fetchForYouUserFailure = (error) => ({
  type: USER_TYPES.FETCH_FOR_YOU_USER_FAILURE,
  payload: error,
});

// FETCH_FOR_YOU_GUEST
export const fetchForYouGuestBegin = () => ({
  type: USER_TYPES.FETCH_FOR_YOU_GUEST_BEGIN,
});

export const fetchForYouGuestSuccess = (data) => ({
  type: USER_TYPES.FETCH_FOR_YOU_GUEST_SUCCESS,
  payload: data,
});

export const fetchForYouGuestFailure = (error) => ({
  type: USER_TYPES.FETCH_FOR_YOU_GUEST_FAILURE,
  payload: error,
});

//GET_FOLLOW_USER_PUBLIC_LIST
export const getFollowUserPublicListBegin = () => ({
  type: USER_TYPES.GET_FOLLOW_USER_PUBLIC_LIST_BEGIN,
});

export const getFollowUserPublicListSuccess = (data) => ({
  type: USER_TYPES.GET_FOLLOW_USER_PUBLIC_LIST_SUCCESS,
  payload: data,
});

export const getFollowUserPublicListFailure = (error) => ({
  type: USER_TYPES.GET_FOLLOW_USER_PUBLIC_LIST_FAILURE,
  payload: error,
});

export const getListBegin = () => ({
  type: USER_TYPES.GET_LIST_BEGIN,
});

export const getListSuccess = (data) => ({
  type: USER_TYPES.GET_LIST_SUCCESS,
  payload: data,
});

export const getListFailure = (error) => ({
  type: USER_TYPES.GET_LIST_FAILURE,
  payload: error,
});

export const getDetailsBegin = () => ({
  type: USER_TYPES.GET_DETAILS_BEGIN,
});

export const getDetailsSuccess = (data) => ({
  type: USER_TYPES.GET_DETAILS_SUCCESS,
  payload: data,
});

export const getDetailsFailure = (error) => ({
  type: USER_TYPES.GET_DETAILS_FAILURE,
  payload: error,
});

export const updateUserBegin = () => ({
  type: USER_TYPES.UPDATE_USER_BEGIN,
});

export const updateUserSuccess = () => ({
  type: USER_TYPES.UPDATE_USER_SUCCESS,
});

export const updateUserFailure = (error) => ({
  type: USER_TYPES.UPDATE_USER_FAILURE,
  payload: error,
});

export const deleteUserBegin = () => ({
  type: USER_TYPES.DELETE_USER_BEGIN,
});

export const deleteUserSuccess = () => ({
  type: USER_TYPES.DELETE_USER_SUCCESS,
});

export const deleteUserFailure = (error) => ({
  type: USER_TYPES.DELETE_USER_FAILURE,
  payload: error,
});

export const getGoalsBegin = () => ({
  type: USER_TYPES.GET_USER_GOALS_BEGIN,
});

export const getGoalsSuccess = (data) => ({
  type: USER_TYPES.GET_USER_GOALS_SUCCESS,
  payload: data,
});

export const getGoalsFailure = (error) => ({
  type: USER_TYPES.GET_USER_GOALS_FAILURE,
  payload: error,
});

export const createUserReportBegin = () => ({
  type: USER_TYPES.CREATE_USER_REPORT_BEGIN,
});

export const createUserReportSuccess = (data) => ({
  type: USER_TYPES.CREATE_USER_REPORT_SUCCESS,
  payload: data,
});

export const createUserReportFailure = (error) => ({
  type: USER_TYPES.CREATE_USER_REPORT_FAILURE,
  payload: error,
});

export const createUserGoalReportBegin = () => ({
  type: USER_TYPES.CREATE_USER_GOAL_REPORT_BEGIN,
});

export const createUserGoalReportSuccess = (data) => ({
  type: USER_TYPES.CREATE_USER_GOAL_REPORT_SUCCESS,
  payload: data,
});

export const createUserGoalReportFailure = (error) => ({
  type: USER_TYPES.CREATE_USER_GOAL_REPORT_FAILURE,
  payload: error,
});

export const getGeneratedReportsListBegin = () => ({
  type: USER_TYPES.GET_GENERATED_REPORTS_LIST_BEGIN,
});

export const getGeneratedReportsListSuccess = (data) => ({
  type: USER_TYPES.GET_GENERATED_REPORTS_LIST_SUCCESS,
  payload: data,
});

export const getGeneratedReportsListFailure = (error) => ({
  type: USER_TYPES.GET_GENERATED_REPORTS_LIST_FAILURE,
  payload: error,
});

export const getReportsSignedUrlBegin = () => ({
  type: USER_TYPES.GET_REPORTS_SIGNED_URL_BEGIN,
});

export const getReportsSignedUrlSuccess = (data) => ({
  type: USER_TYPES.GET_REPORTS_SIGNED_URL_SUCCESS,
  payload: data,
});

export const getReportsSignedUrlFailure = (error) => ({
  type: USER_TYPES.GET_REPORTS_SIGNED_URL_FAILURE,
  payload: error,
});

export const getTimelineBegin = () => ({
  type: USER_TYPES.GET_USER_TIMELINE_BEGIN,
});

export const getTimelineSuccess = (data) => ({
  type: USER_TYPES.GET_USER_TIMELINE_SUCCESS,
  payload: data,
});

export const getTimelineFailure = (error) => ({
  type: USER_TYPES.GET_USER_TIMELINE_FAILURE,
  payload: error,
});

//FETCH PUBLIC/PRRIVATE PROFILE OUTFIT

//FETCH_USER_PROFILE
export const getFetchUserOutfitListBegin = () => ({
  type: USER_TYPES.FETCH_PROFILE_PUBLIC_OUTFIT_BEGIN,
});

export const getFetchUserOutfitListSuccess = (data) => ({
  type: USER_TYPES.FETCH_PROFILE_PUBLIC_OUTFIT_SUCCESS,
  payload: data,
});

export const getFetchUserOutfitListFailure = (error) => ({
  type: USER_TYPES.FETCH_PROFILE_PUBLIC_OUTFIT_FAILURE,
  payload: error,
});

export const setFilters = (data) => ({
  type: USER_TYPES.SET_FILTERS,
  payload: data,
});

export const resetFilters = () => ({
  type: USER_TYPES.RESET_FILTERS,
});
