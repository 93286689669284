import AUTH_TYPES from "../types/authTypes";
import storage from "redux-persist/lib/storage";

const initialState = {
  submitting: false,
  isAuthenticated: false,
  error: null,
  guest: false,
  user: null,
  role: null,
  token: null,
  verifyPassword: false,
  profileData: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_TYPES.RESET_STATE:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case AUTH_TYPES.UPDATE_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };

    case AUTH_TYPES.GUEST_LOGIN_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
        token: null,
      };

    case AUTH_TYPES.GUEST_LOGIN_SUCCESS:
      return {
        ...state,
        submitting: false,
        isAuthenticated: true,
        guest: true,
        user: null,
        role: null,
        token: null,
      };

    case AUTH_TYPES.GUEST_LOGIN_FAILURE:
      return {
        ...state,
        submitting: false,
      };
    case AUTH_TYPES.GUEST_LOGIN_RESET:
      return {
        ...state,
        submitting: false,
        // isAuthenticated: false,
        guest: false,
        // user: null,
        role: null,
        // token: null,
        ...action.payload,
      };

    case AUTH_TYPES.LOGIN_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
        token: null,
      };

    case AUTH_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        submitting: false,
        isAuthenticated: action.payload.isAuthenticated,
        guest: false,
        user: action.payload.user,
        role: action.payload.role,
        token: action.payload.token,
      };

    case AUTH_TYPES.LOGIN_FAILURE:
      return {
        ...state,
        submitting: false,
        loginOtpSent: false,
      };

    case AUTH_TYPES.VERIFYOTP_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
        token: null,
      };

    case AUTH_TYPES.VERIFYOTP_SUCCESS:
      return {
        ...state,
        submitting: false,
        isAuthenticated: false,
        token: action.payload.token,
      };

    case AUTH_TYPES.VERIFYOTP_FAILURE:
      return {
        ...state,
        submitting: false,
        isAuthenticated: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    // Profile Image Update
    case AUTH_TYPES.UPLOAD_PROFILE_IMAGE_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case AUTH_TYPES.UPLOAD_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        submitting: false,
      };

    case AUTH_TYPES.UPLOAD_PROFILE_IMAGE_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    // Profile Update
    case AUTH_TYPES.PROFILE_UPDATE_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case AUTH_TYPES.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        submitting: false,
        isAuthenticated: true,
        user: action.payload,
      };

    case AUTH_TYPES.PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //DEACTIVATE_PROFILE

    case AUTH_TYPES.DEACTIVATE_PROFILE_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case AUTH_TYPES.DEACTIVATE_PROFILE_SUCCESS:
      return {
        ...state,
        submitting: false,
        isAuthenticated: false,
        token: null,
      };

    case AUTH_TYPES.DEACTIVATE_PROFILE_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //DEACTIVATE_PROFILE

    case AUTH_TYPES.DELETE_PROFILE_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case AUTH_TYPES.DELETE_PROFILE_SUCCESS:
      return {
        ...state,
        submitting: false,
        isAuthenticated: false,
      };

    case AUTH_TYPES.DELETE_PROFILE_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case AUTH_TYPES.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
      };

    case AUTH_TYPES.SET_SUBMITTING:
      return {
        ...state,
        submitting: action.payload,
      };

    case AUTH_TYPES.LOGOUT:
      storage.removeItem("persist:auth");
      localStorage.clear();

      return initialState;

    case AUTH_TYPES.GET_PROFILE_DETAILS_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case AUTH_TYPES.GET_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        user: action.payload,
        submitting: false,
        error: null,
      };

    case AUTH_TYPES.GET_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case AUTH_TYPES.VERIFY_EMAIL_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case AUTH_TYPES.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        token: action.payload.token,
        submitting: false,
        error: null,
      };

    case AUTH_TYPES.VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case AUTH_TYPES.RESET_PASSWORD_VERIFY_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case AUTH_TYPES.RESET_PASSWORD_VERIFY_SUCCESS:
      return {
        ...state,
        // isAuthenticated: false,
        token: action.payload.token,
        submitting: false,
        error: null,
      };

    case AUTH_TYPES.RESET_PASSWORD_VERIFY_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };
    case AUTH_TYPES.RESET_PASSWORD_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case AUTH_TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        // isAuthenticated: false,
        // token: null,
        // user: null,
        submitting: false,
        error: null,
      };

    case AUTH_TYPES.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case AUTH_TYPES.POST_SOCIAL_LOGIN_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
        token: null,
      };

    case AUTH_TYPES.POST_SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        submitting: false,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
        role: action.payload.role,
        token: action.payload.token,
      };

    case AUTH_TYPES.POST_SOCIAL_LOGIN_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case AUTH_TYPES.POST_SOCIAL_SIGNUP_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case AUTH_TYPES.POST_SOCIAL_SIGNUP_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        token: action.payload.token,
        submitting: false,
        error: null,
      };

    case AUTH_TYPES.POST_SOCIAL_SIGNUP_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    default:
      return state;
  }
}
