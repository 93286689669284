import axios from "axios";
import { BASE_URL, ENDPOINTS } from "./constants";
import { errorHandler } from "./utils";
import { Mutex } from "async-mutex";
import { store } from "../redux/store";
import { refreshTokenSuccess } from "../redux/actions/authActions";

const mutex = new Mutex();
const makeRequest = async (options) => {
  return new Promise(async (resolve, reject) => {
    try {
      await mutex.waitForUnlock();

      const defaultOptions = {
        baseURL: BASE_URL,
        headers: {
          "Content-Type": "application/json",
          reason: options.data?.reason || null,
        },
        timeout: 60000,
      };

      delete options.data?.reason;

      const client = axios.create(defaultOptions);

      client.interceptors.request.use(
        (config) => {
          const state = store.getState();

          const token = state.auth?.token?.access?.token;

          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      client.interceptors.response.use(
        (res) => {
          return res;
        },
        async (err) => {
          const originalConfig = err.config;

          if (err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
              originalConfig._retry = true;

              try {
                if (!mutex.isLocked()) {
                  const release = await mutex.acquire();
                  const state = store.getState();

                  const refreshToken = state.auth?.token?.refresh?.token;
                  try {
                    const result = await axios.post(
                      `${BASE_URL}${ENDPOINTS.REFRESH_TOKEN}`,
                      {
                        refreshToken: refreshToken,
                      }
                    );
                    await store.dispatch(
                      refreshTokenSuccess({ token: result.data.data })
                    );
                    client.defaults.headers.Authorization = `Bearer ${result.data.data.access.token}`;
                  } catch (err) {
                    errorHandler(err.response);
                  } finally {
                    // release must be called once the mutex should be released again.
                    release();
                  }
                } else {
                  await mutex.waitForUnlock();
                }
                return client(originalConfig);
              } catch (_error) {
                if (_error.response && _error.response.data) {
                  return Promise.reject(_error.response.data);
                }

                return Promise.reject(_error);
              }
            }

            if (err.response.status === 403 && err.response.data) {
              return Promise.reject(err.response.data);
            }
          }

          return Promise.reject(err);
        }
      );

      return client(options)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          errorHandler(error.response ? error.response : { status: 500 });
          return reject(
            error.response && error.response.data.error
              ? error.response.data.error
              : "Something went wrong"
          );
        });
    } catch (err) {
      errorHandler({ status: 500 });
      reject(err.message);
    }
  });
};

export const GET = ({ url, params = {}, data = {} }) =>
  makeRequest({ url, method: "get", params, data });

export const POST = ({ url, params = {}, data = {} }) =>
  makeRequest({ url, method: "post", params, data });

export const PUT = ({ url, params = {}, data = {} }) =>
  makeRequest({ url, method: "put", params, data });

export const PATCH = ({ url, params = {}, data = {} }) =>
  makeRequest({ url, method: "patch", params, data });

export const DELETE = ({ url, params = {}, data = {} }) =>
  makeRequest({ url, method: "delete", params, data });

export default ENDPOINTS;
