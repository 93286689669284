import { useGoogleLogin } from "@react-oauth/google";
import React, { useEffect } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import FacebookLogin from "react-facebook-login";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import closeModal1 from "../../../assets/images/closeModal1.svg";
import { ReactComponent as SocialLink1 } from "../../../assets/images/social/SocialLink1.svg";
import { ReactComponent as SocialLink2 } from "../../../assets/images/social/SocialLink2.svg";
import { ReactComponent as SocialLink3 } from "../../../assets/images/social/SocialLink3.svg";
import Input from "../../../components/Form/Input";
import {
  performSignup,
  performSocialSignup,
} from "../../../redux/actionCreators/authCreators";
import {
  guestLoginReset,
  resetState,
} from "../../../redux/actions/authActions";
import { signupRules } from "../../../services/validations";
import { isValidEmail } from "../../../utils";
import Constants from "../../../utils/constants";
import { parsePhoneNumber } from "libphonenumber-js";

const SignupModal = ({
  showModal,
  handleCloseModal,
  isAuthenticated,
  submitting,
  handleSignup,
  handleSocialSignup,
  data,
  guestLoginReset,
}) => {
  const history = useHistory();

  const Facebooklogin = async (response) => {
    if (response?.accessToken) {
      const newdata = {
        deviceType: "web",
        type: "facebook",
        token: response?.accessToken,
        action: "signup",
      };
      handleSocialSignup(newdata).then((res) => {
        if (res.status) {
          history.push("/userinfo");
        }
      });
    }
  };

  const Googlelogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (codeResponse) => {
      if (codeResponse?.code) {
        const newdata = {
          type: "google",
          deviceType: "web",
          token: codeResponse?.code,
          action: "signup",
        };
        handleSocialSignup(newdata).then((res) => {
          if (res?.status) {
            history.push(`/userinfo`);
          }
        });
      }
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: signupRules,
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  // Get Email or mobile entered values from login form
  if (data) {
    setValue("email", data ? data : data);
  }
  const onSubmit = async (data) => {
    if (!isValidEmail(data.email)) {
      const numberDetails = parsePhoneNumber(
        data.email,
        Constants.COUNTRY_CODE
      );
      if (numberDetails.isValid()) {
        data.mobile = numberDetails.number;
        delete data.email;
      }
    }
    // if (referralCode && referralCode !== "sign-up") {
    //   data.referralCode = referralCode;
    // }
    const result = await handleSignup(data);
    if (result && result?.data?.user?.email !== null) {
      guestLoginReset({ isAuthenticated: false });

      history.push(`/verification`, { data: data.email });
    } else if (result && result?.data?.user?.mobile !== null) {
      history.push(`/otpverification`, { data: data.mobile });
    }

    if (result?.status) {
      handleCloseModal();
      reset();
    }
  };

  useEffect(() => {
    reset();
  }, [reset]);

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body className="w-100 mx-auto">
          <div className="d-flex flex-column align-items-center h-100  justify-content-center py-5 px-4 login_form">
            <Card
              className="w-100 position-relative mx-auto px-4 pt-3 pb-4  "
              style={{ maxWidth: 490 }}
            >
              <button
                onClick={handleCloseModal}
                className="border-0 position-absolute end-0 top-0 me-4 mt-3 bg-transparent"
              >
                <img
                  src={closeModal1}
                  width={20}
                  height={20}
                  alt="close-the-modal"
                />
              </button>
              <Modal.Header className="w-100 justify-content-center pb-0 mt-3 border-0 mb-3">
                <p className="Arial-Bold fw-700">Create an account</p>
              </Modal.Header>

              <Card.Body className="p-0 my-4">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col xs={12} className="mb-3">
                      <Form.Text className="form-label-text">
                        Email or Phone Number
                      </Form.Text>
                      <Input
                        type="text"
                        id="email"
                        name="email"
                        containerClassName="mt-2"
                        label="Enter email or phone number"
                        {...register("email", { required: true })}
                        error={errors.email?.message}
                      />
                    </Col>

                    <Col xs={12} className="mb-3">
                      <Form.Text className="form-label-text">
                        Password
                      </Form.Text>
                      <Input
                        id="password"
                        type="password"
                        name="password"
                        label="Enter password"
                        containerClassName="mt-2"
                        {...register("password", { required: true })}
                        error={errors.password?.message}
                      />
                    </Col>

                    <Col xs={12} className="mb-3">
                      <Form.Text className="form-label-text">
                        Confirm password
                      </Form.Text>
                      <Input
                        id="confirmPassword"
                        type="password"
                        name="password"
                        label="Re-enter password"
                        containerClassName="mt-2"
                        {...register("confirmPassword", { required: true })}
                        error={errors.confirmPassword?.message}
                      />
                    </Col>
                    <Form.Text className="my-3 sign-up-card-info">
                      By signing up, you agree to our Terms, Privacy Policy and
                      Cookie Use. Cheeky may use your contact information,
                      including your email address and phone number for purposes
                      outlined in our Privacy Policy. Learn more.
                    </Form.Text>

                    <Col xs={12} className="position-relative pt-2">
                      <Button
                        type="submit"
                        size="md"
                        className={
                          isValid
                            ? "w-100 login-submit-btn-filled"
                            : "w-100 login-submit-btn"
                        }
                        disabled={submitting}
                      >
                        {!submitting && <span>Continue</span>}
                        {submitting && <Spinner animation="border" />}
                      </Button>
                    </Col>

                    <Col xs={12} className="position-relative mt-2">
                      <div className="d-flex justify-content-center mt-4 ">
                        <span className="social-login-icon">
                          <FacebookLogin
                            appId="879197696736051"
                            autoLoad={false}
                            fields="name,email,picture"
                            callback={Facebooklogin}
                            cssClass="my-facebook-button-class"
                            icon={<SocialLink1 />}
                          />
                        </span>
                        <span
                          className="social-login-icon"
                          onClick={() => Googlelogin()}
                        >
                          <SocialLink2 className="w-100 h-100" />
                        </span>
                        {/* <span className="social-login-icon">
                          <SocialLink3 className="w-100 h-100" />
                        </span>{" "} */}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.auth.submitting,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  reset: () => dispatch(resetState()),
  handleSignup: (payload) => dispatch(performSignup(payload)),
  handleSocialSignup: (payload) => dispatch(performSocialSignup(payload)),
  guestLoginReset: (payload) => dispatch(guestLoginReset(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupModal);
