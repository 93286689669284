const AUTH_TYPES = {
  RESET_STATE: "RESET_STATE",

  UPDATE_USER_DATA: "UPDATE_USER_DATA",

  GUEST_LOGIN_BEGIN: "GUEST_LOGIN_BEGIN",
  GUEST_LOGIN_SUCCESS: "GUEST_LOGIN_SUCCESS",
  GUEST_LOGIN_FAILURE: "GUEST_LOGIN_FAILURE",
  GUEST_LOGIN_RESET: "GUEST_LOGIN_RESET",

  LOGIN_BEGIN: "LOGIN_BEGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_NO_2FA_SUCCESS: "LOGIN_NO_2FA_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  VERIFYOTP_BEGIN: "VERIFYOTP_BEGIN",
  VERIFYOTP_SUCCESS: "VERIFYOTP_SUCCESS",
  VERIFYOTP_FAILURE: "VERIFYOTP_FAILURE",

  VERIFY_EMAIL_BEGIN: "VERIFY_EMAIL_BEGIN",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAILURE: "VERIFY_EMAIL_FAILURE",

  EMAILEXISTCHECK_BEGIN: "EMAILEXISTCHECK_BEGIN",
  EMAILEXISTCHECK_SUCCESS: "EMAILEXISTCHECK_SUCCESS",
  EMAILEXISTCHECK_FAILURE: "EMAILEXISTCHECK_FAILURE",

  USERNAME_EXIST_CHECK_BEGIN: "USERNAME_EXIST_CHECK_BEGIN",
  USERNAME_EXIST_CHECK_SUCCESS: "USERNAME_EXIST_CHECK_SUCCESS",
  USERNAME_EXIST_CHECK_FAILURE: "USERNAME_EXIST_CHECK_FAILURE",

  SIGNUP_BEGIN: "SIGNUP_BEGIN",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",

  PROFILE_UPDATE_BEGIN: "PROFILE_UPDATE_BEGIN",
  PROFILE_UPDATE_SUCCESS: "PROFILE_UPDATE_SUCCESS",
  PROFILE_UPDATE_FAILURE: "PROFILE_UPDATE_FAILURE",

  DEACTIVATE_PROFILE_BEGIN: "DEACTIVATE_PROFILE_BEGIN",
  DEACTIVATE_PROFILE_SUCCESS: "DEACTIVATE_PROFILE_SUCCESS",
  DEACTIVATE_PROFILE_FAILURE: "DEACTIVATE_PROFILE_FAILURE",

  DELETE_PROFILE_BEGIN: "DELETE_PROFILE_BEGIN",
  DELETE_PROFILE_SUCCESS: "DELETE_PROFILE_SUCCESS",
  DELETE_PROFILE_FAILURE: "DELETE_PROFILE_FAILURE",

  UPLOAD_PROFILE_IMAGE_BEGIN: "GET_UPLOAD_PROFILE_IMAGE_BEGIN",
  UPLOAD_PROFILE_IMAGE_SUCCESS: "GET_UPLOAD_PROFILE_IMAGE_SUCCESS",
  UPLOAD_PROFILE_IMAGE_FAILURE: "GET_UPLOAD_PROFILE_IMAGE_FAILURE",

  REFRESH_TOKEN_BEGIN: "REFRESH_TOKEN_BEGIN",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_FAILURE: "REFRESH_TOKEN_FAILURE",

  CHANGE_PASSWORD_BEGIN: "CHANGE_PASSWORD_BEGIN",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  FORGOT_PASSWORD_BEGIN: "FORGOT_PASSWORD_BEGIN",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  RESET_PASSWORD_BEGIN: "RESET_PASSWORD_BEGIN",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  RESEND_OTP_BEGIN: "RESEND_OTP_BEGIN",
  RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
  RESEND_OTP_FAILURE: "RESEND_OTP_FAILURE",

  RESET_PASSWORD_VERIFY_BEGIN: "RESET_PASSWORD_VERIFY_BEGIN",
  RESET_PASSWORD_VERIFY_SUCCESS: "RESET_PASSWORD_VERIFY_SUCCESS",
  RESET_PASSWORD_VERIFY_FAILURE: "RESET_PASSWORD_VERIFY_FAILURE",

  POST_SOCIAL_LOGIN_BEGIN: "POST_SOCIAL_LOGIN_BEGIN",
  POST_SOCIAL_LOGIN_SUCCESS: "POST_SOCIAL_LOGIN_SUCCESS",
  POST_SOCIAL_LOGIN_FAILURE: "POST_SOCIAL_LOGIN_FAILURE",

  POST_SOCIAL_SIGNUP_BEGIN: "POST_SOCIAL_SIGNUP_BEGIN",
  POST_SOCIAL_SIGNUP_SUCCESS: "POST_SOCIAL_SIGNUP_SUCCESS",
  POST_SOCIAL_SIGNUP_FAILURE: "POST_SOCIAL_SIGNUP_FAILURE",

  SET_SUBMITTING: "SET_SUBMITTING",

  LOGOUT: "LOGOUT",

  GET_PROFILE_DETAILS_BEGIN: "GET_PROFILE_DETAILS_BEGIN",
  GET_PROFILE_DETAILS_SUCCESS: "GET_PROFILE_DETAILS_SUCCESS",
  GET_PROFILE_DETAILS_FAILURE: "GET_PROFILE_DETAILS_FAILURE",
};

export default AUTH_TYPES;
