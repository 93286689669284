import { useEffect, useRef, useState } from "react";

const useCartLengthMonitor = (array) => {
  const prevLengthRef = useRef(0);
  const [changedLength, setChangedLength] = useState(null);

  useEffect(() => {
    const currentLength = array ? array.length : 0;

    if (currentLength !== prevLengthRef.current) {
      setChangedLength(currentLength); // Update the changed length state
      prevLengthRef.current = currentLength; // Update previous length
    }
  }, [array]);

  return changedLength;
};

export default useCartLengthMonitor;
