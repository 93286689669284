import AUTH_TYPES from "../types/authTypes";

export const resetState = () => ({
  type: AUTH_TYPES.RESET_STATE,
});

export const updateUserData = (data) => ({
  type: AUTH_TYPES.UPDATE_USER_DATA,
  payload: data,
});

// Guest login
export const guestLoginBegin = () => ({
  type: AUTH_TYPES.GUEST_LOGIN_BEGIN,
});

export const guestLoginSuccess = (data) => ({
  type: AUTH_TYPES.GUEST_LOGIN_SUCCESS,
  payload: data,
});

export const guestLoginFailure = (error) => ({
  type: AUTH_TYPES.GUEST_LOGIN_FAILURE,
  payload: { error },
});
export const guestLoginReset = (data) => ({
  type: AUTH_TYPES.GUEST_LOGIN_RESET,
  payload: data,
});

// Login
export const loginBegin = () => ({
  type: AUTH_TYPES.LOGIN_BEGIN,
});

export const loginSuccess = (data) => ({
  type: AUTH_TYPES.LOGIN_SUCCESS,
  payload: data,
});

export const loginFailure = (error) => ({
  type: AUTH_TYPES.LOGIN_FAILURE,
  payload: { error },
});

// Social login
export const socialLoginBegin = () => ({
  type: AUTH_TYPES.POST_SOCIAL_LOGIN_BEGIN,
});

export const socialLoginSuccess = (data) => ({
  type: AUTH_TYPES.POST_SOCIAL_LOGIN_SUCCESS,
  payload: data,
});

export const socialLoginFailure = (error) => ({
  type: AUTH_TYPES.POST_SOCIAL_LOGIN_FAILURE,
  payload: { error },
});

//Social Signup
export const socialSignupBegin = () => ({
  type: AUTH_TYPES.POST_SOCIAL_SIGNUP_BEGIN,
});

export const socialSignupSuccess = (data) => ({
  type: AUTH_TYPES.POST_SOCIAL_SIGNUP_SUCCESS,
  payload: data,
});

export const socialSignupFailure = (error) => ({
  type: AUTH_TYPES.POST_SOCIAL_SIGNUP_FAILURE,
  payload: { error },
});

//verify-otp
export const VerifyOtpBegin = () => ({
  type: AUTH_TYPES.VERIFYOTP_BEGIN,
});

export const VerifyOtpSuccess = (data) => ({
  type: AUTH_TYPES.VERIFYOTP_SUCCESS,
  payload: data,
});

export const VerifyOtpFailure = (error) => ({
  type: AUTH_TYPES.VERIFYOTP_FAILURE,
  payload: { error },
});

//emailExistCheck
export const emailExistCheckBegin = () => ({
  type: AUTH_TYPES.EMAILEXISTCHECK_BEGIN,
});

export const emailExistCheckSuccess = (data) => ({
  type: AUTH_TYPES.EMAILEXISTCHECK_SUCCESS,
  payload: data,
});

export const emailExistCheckFailure = (error) => ({
  type: AUTH_TYPES.EMAILEXISTCHECK_FAILURE,
  payload: { error },
});

//EMAIL EXIST CHECK Check
export const usernameExistCheckBegin = () => ({
  type: AUTH_TYPES.USERNAME_EXIST_CHECK_BEGIN,
});

export const usernameExistCheckSuccess = (data) => ({
  type: AUTH_TYPES.USERNAME_EXIST_CHECK_SUCCESS,
  payload: data,
});

export const usernameExistCheckFailure = (error) => ({
  type: AUTH_TYPES.USERNAME_EXIST_CHECK_FAILURE,
  payload: { error },
});

//signup
export const signupBegin = () => ({
  type: AUTH_TYPES.SIGNUP_BEGIN,
});

export const signupSuccess = (data) => ({
  type: AUTH_TYPES.SIGNUP_SUCCESS,
  payload: data,
});

export const signupFailure = (error) => ({
  type: AUTH_TYPES.SIGNUP_FAILURE,
  payload: { error },
});

//Upload profile Image

export const UploadProfileImageBegin = () => ({
  type: AUTH_TYPES.UPLOAD_PROFILE_IMAGE_BEGIN,
});

export const UploadProfileImageSuccess = (data) => ({
  type: AUTH_TYPES.UPLOAD_PROFILE_IMAGE_SUCCESS,
  payload: data,
});

export const UploadProfileImageFailure = (error) => ({
  type: AUTH_TYPES.UPLOAD_PROFILE_IMAGE_FAILURE,
  payload: error,
});

export const refreshTokenSuccess = (data) => ({
  type: AUTH_TYPES.REFRESH_TOKEN_SUCCESS,
  payload: data,
});

export const changePasswordBegin = () => ({
  type: AUTH_TYPES.CHANGE_PASSWORD_BEGIN,
});

export const changePasswordSuccess = () => ({
  type: AUTH_TYPES.CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailure = (error) => ({
  type: AUTH_TYPES.CHANGE_PASSWORD_FAILURE,
  payload: { error },
});

export const setSubmitting = (data) => ({
  type: AUTH_TYPES.SET_SUBMITTING,
  payload: data,
});

export const logout = () => ({
  type: AUTH_TYPES.LOGOUT,
});

export const getProfileDetailsBegin = () => ({
  type: AUTH_TYPES.GET_PROFILE_DETAILS_BEGIN,
});

export const getProfileDetailsSuccess = (data) => ({
  type: AUTH_TYPES.GET_PROFILE_DETAILS_SUCCESS,
  payload: data,
});

export const getProfileDetailsFailure = (error) => ({
  type: AUTH_TYPES.GET_PROFILE_DETAILS_FAILURE,
  payload: { error },
});

//Forgot Password
export const forgotPasswordBegin = () => ({
  type: AUTH_TYPES.FORGOT_PASSWORD_BEGIN,
});

export const forgotPasswordSuccess = (data) => ({
  type: AUTH_TYPES.FORGOT_PASSWORD_SUCCESS,
  payload: data,
});

export const forgotPasswordFailure = (error) => ({
  type: AUTH_TYPES.FORGOT_PASSWORD_FAILURE,
  payload: { error },
});

//Reset Password
export const resetPasswordBegin = () => ({
  type: AUTH_TYPES.RESET_PASSWORD_BEGIN,
});

export const resetPasswordSuccess = (data) => ({
  type: AUTH_TYPES.RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const resetPasswordFailure = (error) => ({
  type: AUTH_TYPES.RESET_PASSWORD_FAILURE,
  payload: { error },
});

//Resend OTP
export const resendOTPBegin = () => ({
  type: AUTH_TYPES.RESEND_OTP_BEGIN,
});

export const resendOTPSuccess = (data) => ({
  type: AUTH_TYPES.RESEND_OTP_SUCCESS,
  payload: data,
});

export const resendOTPFailure = (error) => ({
  type: AUTH_TYPES.RESEND_OTP_FAILURE,
  payload: { error },
});

//Reset Password Verify
export const resetPasswordVerifyBegin = () => ({
  type: AUTH_TYPES.RESET_PASSWORD_VERIFY_BEGIN,
});

export const resetPasswordVerifySuccess = (data) => ({
  type: AUTH_TYPES.RESET_PASSWORD_VERIFY_SUCCESS,
  payload: data,
});

export const resetPasswordVerifyFailure = (error) => ({
  type: AUTH_TYPES.RESET_PASSWORD_VERIFY_FAILURE,
  payload: { error },
});

//profile update
export const profileUpdateBegin = () => ({
  type: AUTH_TYPES.PROFILE_UPDATE_BEGIN,
});

export const profileUpdateSuccess = (data) => ({
  type: AUTH_TYPES.PROFILE_UPDATE_SUCCESS,
  payload: data,
});

export const profileUpdateFailure = (error) => ({
  type: AUTH_TYPES.PROFILE_UPDATE_FAILURE,
  payload: { error },
});

//profile update
export const verifyEmailBegin = () => ({
  type: AUTH_TYPES.VERIFY_EMAIL_BEGIN,
});

export const verifyEmailSuccess = (data) => ({
  type: AUTH_TYPES.VERIFY_EMAIL_SUCCESS,
  payload: data,
});

export const verifyEmailFailure = (error) => ({
  type: AUTH_TYPES.VERIFY_EMAIL_FAILURE,
  payload: { error },
});

//DEACTIVATE_PROFILE
export const deactivateProfileBegin = () => ({
  type: AUTH_TYPES.DEACTIVATE_PROFILE_BEGIN,
});

export const deactivateProfileSuccess = (data) => ({
  type: AUTH_TYPES.DEACTIVATE_PROFILE_SUCCESS,
  payload: data,
});

export const deactivateProfileFailure = (error) => ({
  type: AUTH_TYPES.DEACTIVATE_PROFILE_FAILURE,
  payload: { error },
});

//DELETE_PROFILE
export const deleteProfileBegin = () => ({
  type: AUTH_TYPES.DELETE_PROFILE_BEGIN,
});

export const deleteProfileSuccess = (data) => ({
  type: AUTH_TYPES.DELETE_PROFILE_SUCCESS,
  payload: data,
});

export const deleteProfileFailure = (error) => ({
  type: AUTH_TYPES.DELETE_PROFILE_FAILURE,
  payload: { error },
});
