import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { Container, Form, FormControl, Nav, Navbar } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from "react-router-dom";
import cart from "../../assets/images/cart.svg";
import clear from "../../assets/images/clear.svg";
import logosmall from "../../assets/images/logosmall.svg";
import { ReactComponent as NotificationBtn } from "../../assets/images/notification-btn.svg";
import searchicon from "../../assets/images/searchicon.svg";
import searchtime from "../../assets/images/searchtime.svg";
import SignupModal from "../../containers/Auth/Modals/SignupModal";
import { performSearchUserByName } from "../../redux/actionCreators/accountCreators";
import { openCartPopup, openLoginModal } from "../../redux/actions/userActions";
import Cart from "../Cart";
import DropdownMenu from "../DropdownMenu";
import Logo from "../Logo";
import NotificationBox from "../NotificationBox";
import LoginModal from "../../containers/Auth/Modals/LoginModal";
import ReactSelect from "../../components/Form/Select";
import { getImageUrl } from "../../services/utils";
import { DEFAULT_PROFILE_IMAGE } from "../../services/constants";

const options = [
  { value: "", label: "All" },
  { value: "profile", label: "Profile" },
  { value: "list", label: "List" },
  { value: "product", label: "Product" },
  // { value: "subCategory", label: "Tags" },
];

const Header = ({
  handleLogout,
  user,
  searchUserByName,
  guest,
  isCartOpen,
  loginModalOpen,
}) => {
  const history = useHistory();
  const resultsRef = useRef(null);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [signupModal, setSignupModal] = useState(false); //Signup Modal
  const [loginModal, setLoginModal] = useState(false); //Login Modal
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false); //Controlling Search Div behaviour
  const [notificationSlide, setNotificationSlide] = useState(false);
  const [searchUserResult, setSearchUserResult] = useState([]);
  const [searchListResult, setSearchListResult] = useState([]);
  const [searchProductResult, setSearchProductResult] = useState([]);
  const [searchTagResult, setSearchTagResult] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [combinedData, setCombinedData] = useState({
    products: [],
    tags: [],
  });
  const [isSmallScreen, setIsSmallScreen] = useState(false); // Handle Logo Change
  const [searchType, setSearchType] = useState("");
  const showRoom = useSelector((state) => state.user.wardrobeRoom);
  const [signupModalData, setSignupModalData] = useState({});

  // Handle Logout
  const handleUserLogout = () => {
    handleLogout();
  };

  const searchTypeHandler = (type) => {
    if (type?.value === "All") {
      setSearchType({ ...type, value: "" });
    } else {
      setSearchType(type);
    }
  };

  // Handle Logo Change
  useLayoutEffect(() => {
    function updateScreenSize() {
      setIsSmallScreen(window.innerWidth <= 768);
    }

    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();

    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  const showNotificaton = () => {
    setNotificationSlide(true);
  };
  const hideNotificaton = () => {
    setNotificationSlide(false);
  };

  //Cart Drawer Modal
  const dispatch = useDispatch();
  const handleToggle = () => {
    dispatch(openCartPopup());
  };

  //handle Login Modal
  const handleLoginModalOpen = () => {
    dispatch(openLoginModal());
  };

  // Search User

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Load recent searches from localStorage
  useEffect(() => {
    const storedRecentSearches = localStorage.getItem("recentSearches");
    if (storedRecentSearches) {
      setRecentSearches(JSON.parse(storedRecentSearches));
    }
  }, []);

  useEffect(() => {
    let delayDebounceFn;

    if (guest === false) {
      if (searchTerm) {
        const data = { search: searchTerm, searchType: searchType?.value };
        delayDebounceFn = setTimeout(async () => {
          const result = await searchUserByName(data);
          if (result) {
            setShow(true);
            setSearchUserResult(result?.data?.userSearch);
            setSearchProductResult(result?.data?.products);
            setSearchListResult(result?.data?.list);
            setSearchTagResult(result?.data?.subCategory);
            // Combine products and tags for showing in results
            setCombinedData((prevData) => ({
              products: result?.data?.products,
              tags: result?.data?.subCategory,
              userSearch: result?.data?.userSearch,
              list: result?.data?.list,
            }));

            setRecentSearches((prevSearches) => {
              if (prevSearches.includes(searchTerm)) {
                return prevSearches;
              }
              const updatedSearches = [
                searchTerm,
                ...prevSearches?.slice(0, 3),
              ];
              localStorage.setItem(
                "recentSearches",
                JSON.stringify(updatedSearches)
              );
              return updatedSearches;
            });
          }
        }, 500);
      } else {
        setShow(false);
      }

      return () => clearTimeout(delayDebounceFn);
    } else if (guest && searchTerm.length > 0) {
      setTimeout(() => {
        handleLoginModalOpen();
        setSearchTerm("");
      }, 1000);
    }
  }, [searchTerm, guest, searchType]);

  // Clear Recent Search Results in search
  const handleClearRecentSearch = (index) => {
    setRecentSearches((prevSearches) => {
      const updatedSearches = [...prevSearches];
      updatedSearches.splice(index, 1);
      localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
      return updatedSearches;
    });
  };

  // Put Recent Search into search
  const handleClickRecentSearch = (item) => {
    setSearchTerm(item);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !resultsRef.current?.contains(event.target) &&
        !dropdownRef.current?.contains(event.target) &&
        !inputRef.current?.contains(event.target)
      ) {
        setShow(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Goto The Searched User Profile page
  const handleUserProfileRedirect = (id) => {
    history.push(`/people-profile/${id}`);
    setShow(false);
  };

  // Goto List page
  const handleListRedirect = (item) => {
    history.push({
      pathname:
        user?._id === item?.userId
          ? `/list-items/${item?.listId}`
          : `/people/${item?.userId}/user-list/${item?.listId}`,
      state: {
        data: item?.listTitle,
      },
    });
    setShow(false);
  };

  // Goto Search Result page
  const handleSearchResultRedirect = (tag) => {
    history.push(`/search-results`, { data: combinedData, tagData: tag });
    setShow(false);
  };

  const handleCloseSignupModal = () => {
    setSignupModal(false);
    setSignupModalData({});
  };

  // Login Modal Open
  const handleCloseLoginModal = () => {
    setLoginModal(false);
  };

  // Signup Modal Open
  const handleShowSignupModal = (item) => {
    // e.stopPropagation();
    setSignupModal(true);
    handleCloseLoginModal();
    if (item) {
      setSignupModalData(item);
    }
    // history.push(`/sign-up`);
  };

  return (
    <>
      <Navbar sticky="top" className="header">
        <Container fluid className="px-4">
          <Navbar.Brand
            as={RouterLink}
            to={{
              pathname: "/dashboard",
              state: { isListUpdated: guest ? false : true },
            }}
          >
            {isSmallScreen || showRoom ? (
              <div style={{ marginLeft: "16px" }}>
                <img src={logosmall} alt="logosmall" width={32} height={32} />
              </div>
            ) : (
              <Logo maxWidth={"6rem"} />
            )}
          </Navbar.Brand>

          <Form
            className="search-bar-container"
            style={{ left: showRoom && "150px" }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSearchResultRedirect();
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearchResultRedirect();
              }
            }}
          >
            <div className="search-icon-container">
              <img src={searchicon} alt="searchicon" />
            </div>
            <FormControl
              type="text"
              ref={inputRef}
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search for lists, username, product, tags"
              className="search-input"
              onFocus={() => {
                setShow(true);
              }}
            />

            {/* {!searchTerm && ( */}
            <ReactSelect
              value={searchType}
              onChange={searchTypeHandler}
              options={options}
              ref={dropdownRef}
              placeholder="All"
              styles={customStylesSelect}
              wrapperClassName={"pb-0"}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
            {/* // )} */}
            {searchTerm && (
              <img
                src={clear}
                alt="clear"
                className="pointer me-3 ms-1"
                onClick={() => {
                  setSearchListResult([]);
                  setSearchUserResult([]);
                  setSearchTagResult([]);
                  setSearchProductResult([]);
                  setSearchTerm("");
                }}
              />
            )}
            {show && (
              <div className="search-results" ref={resultsRef}>
                {/* Product Search */}
                {searchProductResult?.length > 0 && (
                  <>
                    <p className="category-heading">Products :</p>
                    {searchProductResult?.map((item, index) => (
                      <div
                        className="user-card"
                        key={index}
                        onClick={() => handleSearchResultRedirect()}
                      >
                        <div className="profile-image bg-white m-2 rounded-circle">
                          <img
                            className="rounded-circle   "
                            src={
                              item?.image
                                ? item?.image
                                : DEFAULT_PROFILE_IMAGE
                            }
                            alt="profile"
                            style={{
                              objectFit: "contain",
                              objectPosition: "center",
                              height: "100%",
                              width: "40px",
                            }}
                          />
                        </div>
                        <div className="user-details">
                          <p
                            style={{ color: "#fff" }}
                            className="text-truncate-1"
                          >
                            {item?.title}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {/* Lists Search */}
                <>
                  {searchListResult?.length > 0 && (
                    <>
                      <p className="category-heading mt-2">Lists :</p>
                      {searchListResult?.map((item, index) => (
                        <div key={index} className="search-list-container mt-1">
                          <img src={searchicon} alt="searchicon" />
                          <p onClick={() => handleListRedirect(item)}>
                            {item?.listTitle}
                          </p>
                        </div>
                      ))}
                    </>
                  )}
                </>

                <>
                  {searchUserResult?.length > 0 && (
                    <>
                      <p className="category-heading mt-2">Users :</p>
                      {searchUserResult?.map((item, index) => (
                        <div
                          className="user-card"
                          key={index}
                          onClick={() => handleUserProfileRedirect(item?._id)}
                        >
                          <div className="profile-image bg-white m-2 rounded-circle">
                            <img
                              className="rounded-circle   "
                              src={
                                item.profileImage
                                  ? getImageUrl(item?.profileImage)
                                  : DEFAULT_PROFILE_IMAGE
                              }
                              alt="profile"
                              style={{
                                objectFit: "cover",
                                objectPosition: "center",
                                height: "100%",
                                width: "40px",
                              }}
                            />
                          </div>

                          <div className="user-details">
                            <h3>{`${item?.firstName || ""} ${
                              item?.lastName || ""
                            }`}</h3>
                            {item?.username && (
                              <h6>{`@${item?.username || ""}`}</h6>
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>

                {/* Tags Search */}
                {searchTagResult?.length > 0 && (
                  <div className="suggested-tabs-container d-flex align-items-stretch my-2">
                    <p className="category-heading mt-1">Tags :</p>
                    {searchTagResult?.map((item, index) => (
                      <div
                        className="suggested-tabs"
                        key={index}
                        onClick={() => {
                          history.push({
                            pathname: "/explore/list",
                            state: { data: item },
                          });
                          setShow(false);
                        }}
                      >
                        <span>{item?.subCategory}</span>
                      </div>
                    ))}
                  </div>
                )}

                {!searchUserResult?.length &&
                  !searchListResult?.length &&
                  !searchTagResult?.length &&
                  !searchProductResult?.length && (
                    <>
                      <p className="result-text">No results found</p>
                    </>
                  )}

                <div className="recent-search-container mt-3">
                  <p className="recent-search-heading">Recent Searches</p>
                  <div className="recent-search">
                    {recentSearches?.slice(1)?.map((item, index) => (
                      <div className="recent" key={index}>
                        <div className="name-and-recent-icon ">
                          <img src={searchtime} alt="searchtime" />
                          <span
                            style={{ color: "#7A7A7A" }}
                            onClick={() => handleClickRecentSearch(item)}
                            className="text-truncate-1 "
                          >
                            {item}
                          </span>
                        </div>
                        <img
                          src={clear}
                          alt="clear"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => handleClearRecentSearch(index)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </Form>

          <Nav className="ms-auto align-items-center">
            <div className="d-flex justify-content-between align-items-center gap-3 mx-4">
              {/* <div>
                <span
                  className="balance_header Arial-Bold fw-500"
                  onClick={() =>
                    guest ? handleLoginModalOpen() : history.push("/balance")
                  }
                >
                  {`$${guest ? 0 : user?.walletAmount || 0}`}
                </span>
              </div> */}
              {/* <div>
                {!guest && (
                  <span
                    className="notification"
                    onClick={() =>
                      guest ? handleLoginModalOpen() : showNotificaton()
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <NotificationBtn />
                  </span>
                )}
              </div> */}

              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  guest ? handleLoginModalOpen() : handleToggle()
                }
                className="notification"
              >
                <img src={cart} alt="cart" />
              </span>
            </div>

            {guest ? (
              <div className="header-buttons">
                <button
                  className="header-login"
                  onClick={() => handleLoginModalOpen()}
                >
                  <span>Login</span>
                </button>

                <button
                  className="header-signup"
                  onClick={(e) => handleShowSignupModal(e)}
                >
                  <span>Signup</span>
                </button>
              </div>
            ) : (
              <DropdownMenu
                name={`${user?.firstName} ${user?.lastName} `}
                menu={[
                  {
                    label: "Profile",
                    onClick: () => history.push("/account"),
                  },

                  {
                    label: "Settings",
                    onClick: () => history.push("/account/edit"),
                  },
                  {
                    label: "Sign Out",
                    onClick: () => handleUserLogout(),
                  },
                ]}
                style={{
                  button: {
                    padding: 0,
                    fontWeight: 700,
                  },
                  menu: {
                    minWidth: 150,
                    backgroundColor: "#000000 !important",
                    color: "white !important",
                  },
                }}
                icon={
                  <Avatar
                    src={user && user.avatar ? user.avatar : null}
                    name={`${user?.firstName} ${user?.lastName}`}
                    size="32"
                    className="ff-primary"
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  />
                }
              />
            )}
          </Nav>
        </Container>
      </Navbar>
      <NotificationBox
        notificationSlide={notificationSlide}
        hideNotificaton={hideNotificaton}
      />
      {loginModalOpen && (
        <LoginModal
          showModal={loginModal}
          handleCloseModal={handleCloseLoginModal}
          setSignupModalData={setSignupModalData}
          handleShowSignupModal={handleShowSignupModal}
        />
      )}
      {signupModal && (
        <SignupModal
          showModal={signupModal}
          handleCloseModal={handleCloseSignupModal}
          data={signupModalData?.mobile || signupModalData?.email}
        />
      )}
      {isCartOpen && <Cart onClose={handleToggle} />}
    </>
  );
};

Header.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

Header.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  guest: state.auth.guest,
  isCartOpen: state.user.isCartOpen,
  loginModalOpen: state.user.isLoginModalOpen,
});

const mapDispatchToProps = (dispatch) => ({
  searchUserByName: (payload) => dispatch(performSearchUserByName(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
const customStylesSelect = {
  dropdownIndicator: (base) => ({
    ...base,
    color: "black",
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    background: "#fff !important",
    width: "max-content !important",
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),

  input: (base, state) => ({
    ...base,
    height: "unset",
    color: "#242424 !important",
    fontWeight: "500",
  }),
  valueContainer: (base, state) => ({
    ...base,
    padding: "0",
  }),
  placeholder: (base, state) => ({
    ...base,
    color: "#242424 !important",
    fontWeight: "500",
  }),
  singleValue: (base, state) => ({
    ...base,
    lineHeight: "125%",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    marginRight: "0.5rem",
    paddingLeft: "0",
  }),

  menu: (base) => ({
    ...base,
    backgroundColor: "#121212 !important",
    padding: "0px 10px !important",
    width: "7rem !important",
    borderRadius: "8px !important",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    cursor: "pointer",
    backgroundColor: isFocused ? "#121212 !important" : null,
    borderBottom: "1px solid #7a7a7a !important",
    color: isSelected ? "#fff" : "#a7a7a7",
    "&:last-of-type ": {
      borderBottom: "0 !important",
    },
    "&:hover ": {
      color: "#fff",
    },
  }),
};
