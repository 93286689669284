import React, { useRef, useEffect } from "react";
import setting from "../../assets/images/Settings.png";
import notificationimg1 from "../../assets/images/notficationimg1.png";
import notificationimg2 from "../../assets/images/notiicationimg2.png";
import notificationimg3 from "../../assets/images/notificationimg3.png";
import notificationimg4 from "../../assets/images/notificationimg4.png";
import { useHistory } from "react-router-dom";

const NotificationBox = ({ notificationSlide, hideNotificaton }) => {
  const history = useHistory();
  const NotificationSectionBox = ({
    img,
    headNotify,
    decsNotify,
    priceNotify,
  }) => {
    return (
      <div className="image-section d-flex mt-3 mb-4">
        <img
          src={img}
          onClick={() => hideNotificaton()}
          alt="notify"
          style={{ cursor: "pointer" }}
        />
        <div className="d-flex flex-column justify-content-center image-section-notify">
          <div className="head-notify text-truncate-2">{headNotify}</div>
          <div className="desc-notify text-truncate-2">{decsNotify}</div>
          <div className="price-notify mt-3">{priceNotify}</div>
        </div>
      </div>
    );
  };

  const notificationRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        hideNotificaton();
      }
    };
    if (notificationSlide) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationSlide, hideNotificaton]);

  return (
    <>
      {notificationSlide && (
        <div className="notification-section" ref={notificationRef}>
          <div className="notification-icon d-flex justify-content-between align-items-center w-100">
            <div className="text">Notifications</div>
            <div
              className="icon"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/account/notification-settings");
                hideNotificaton();
              }}
            >
              <img src={setting} alt="setting" />
            </div>
          </div>
          <div className="time-section">
            <div className="time">Today</div>
            <NotificationSectionBox
              img={notificationimg1}
              headNotify={"Order has been Shipped"}
              decsNotify={"You order will reach you in next 12 days."}
              priceNotify={"$250"}
            />
            <div className="border-notify"></div>
            <NotificationSectionBox
              img={notificationimg2}
              headNotify={"Item back in stock"}
              decsNotify={
                "Nike re stocked this item. Get it before it is sold out."
              }
              priceNotify={"$250"}
            />
          </div>
          <div className="time-section">
            <div className=" time">2 weeks ago</div>
            <NotificationSectionBox
              img={notificationimg3}
              headNotify={"15% off on this item"}
              decsNotify={
                "Forever 21 sale is live and the item you added is on sale."
              }
              priceNotify={"$250"}
            />
            <div className="border-notify"></div>
            <NotificationSectionBox
              img={notificationimg4}
              headNotify={"Item back in stock"}
              decsNotify={
                "Nike re stocked this item. Get it before it is sold out."
              }
              priceNotify={"$250"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationBox;
