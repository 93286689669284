import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Nav,
  NavItem,
  Row,
  Spinner,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { BsBoxSeam } from "react-icons/bs";
import { CgHome } from "react-icons/cg";
import { FaListUl } from "react-icons/fa";
import { FiCompass, FiKey, FiUser } from "react-icons/fi";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect, useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import ReactSelect from "../../components/Form/Select";

import { ReactComponent as Notification } from "../../assets/images/Notificationwhite.svg";
import { ReactComponent as ReferralIcon } from "../../assets/images/Referralicon.svg";
import { ReactComponent as Home } from "../../assets/images/home.svg";
import closeModal1 from "../../assets/images/closeModal1.svg";
import { ReactComponent as Wardrobe } from "../../assets/images/wardrobe.svg";
import { ReactComponent as WishlistIcon } from "../../assets/images/wishlist.svg";
import {
  performCreateYourLists,
  performGetCategoryItemsSelected,
  performGetYourLists,
} from "../../redux/actionCreators/userCreators";
import { validateRoutesAccess } from "../../utils";
import Constants from "../../utils/constants";
import Input from "../Form/Input";
import {
  closeWardrobeRoomDrawer,
  openLoginModal,
  openWardrobeRoomDrawer,
} from "../../redux/actions/userActions";
import NextArrow from "../SliderUtils/NextArrow";
import PreviousArrow from "../SliderUtils/PreviousArrow";
import Slider from "react-slick";
import { getRandomColor } from "../../services/utils";
import { userListRules } from "../../services/validations";

const Sidebar = ({
  guest,
  loggedInUser,
  getYourLists,
  CreateYourList,
  categoryItemSelected,
  list,
  fetching,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: userListRules,
    defaultValues: {
      title: "",
      isPublic: false,
      tags: [],
    },
  });

  const [tags, title] = watch(["tags", "title"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const showRoom = useSelector((state) => state.user.wardrobeRoom);
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null); // Set selected List Items
  const [wishlistSelected, setWishlistSelected] = useState(false); // Set selected List Items
  const [isOverflowing, setIsOverflowing] = useState(false); // For Creat New List button Position Change to List Top
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [sidebarLists, setSidebarLists] = useState([]);
  const [page, setPage] = useState(1);
  const DISCLOSURE_STATEMENT = "This website contains affiliate links. We may earn a commission if you make a purchase through these links, at no additional cost to you.";

  const fetchSidebarLists = useCallback(async () => {
    if (guest) return;

    let data = { page };

    try {
      const result = await getYourLists(data);
      if (result && result.data && result.data.docs) {
        let filteredDocs = result?.data?.docs;
        const formattedData = result.data.docs.map(item => ({
          title: item?.title,
          id: item?._id,
          isPublic: item?.isPublic,
          isActive: item?.isActive,
          isOwnList: item?.userId === loggedInUser?._id,
          ownerName: item?.ownerName,
          isLiked: item?.isLiked,
        }));

        setSidebarLists(formattedData);
      } else {
        console.error("Unexpected API response structure:", result);
      }
    } catch (error) {
      console.error("Error fetching lists:", error);
    }
  }, [getYourLists, guest, loggedInUser]);

  useEffect(() => {
    fetchSidebarLists(page);
  }, [fetchSidebarLists, page]);

  useEffect(() => {
    if (location?.state?.isListUpdated) {
      fetchSidebarLists();
      history.replace({
        pathname: location.pathname,
        state: null,
      });
    }
  }, [location?.state?.isListUpdated, fetchSidebarLists, history]);


  //handle Login Modal
  const handleLoginModalOpen = () => {
    dispatch(openLoginModal());
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClose = () => {
    setOptions([]);
    setSelectedItemIndex(null);
    setShowModal(false);
    setIsOn(false);
    reset();
  };
  const handleShow = () => setShowModal(true);

  const setData = (result) => {
    const data = [];
    if (result && result.docs) {
      result.docs.forEach((item, index) => {
        const objData = {
          title: item?.title,
          id: item?._id,
          isPublic: item?.isPublic,
          isActive: item?.isActive,
          isOwnList: item?.userId === loggedInUser?._id,
          ownerName: item?.ownerName,
        };
        data.push(objData);
      });
    } else {
      console.log('No docs found in result');
    }
    const yourListsRoute = routes.find(route => route.key === Constants.ROLE_ATTRIBUTES.YOURLISTS);
    if (yourListsRoute) {
      yourListsRoute.children = data;
    }
    setAllowedRoutes([...routes]);
  };

  // UseEffect For sidebar array
  const updateAllowedRoutes = useCallback(() => {
    const routesToUse = path.includes("/account/") ? accountRoutes : routes;
    const { children } = validateRoutesAccess(
      { children: routesToUse },
      loggedInUser ? loggedInUser.role : undefined
    );
    const allowedMenus = [];
    children.forEach((childRoute) => {
      if (childRoute?.isValidRole) {
        allowedMenus.push(childRoute);
      }
    });
    setAllowedRoutes(allowedMenus);
  }, [loggedInUser, path]);

  useEffect(() => {
    updateAllowedRoutes();

    // For Creat New List button Position Change to List Top
    const listContainer = document.querySelector(".your-lists-items-container");
    const listItems = document.querySelector(".your-lists-items");

    if (listContainer && listItems) {
      const isOverflow = listItems.scrollHeight > listContainer.offsetHeight;
      setIsOverflowing(isOverflow);
    }
  }, [loggedInUser, path, updateAllowedRoutes]);

  const FetchCategoryItemSelected = useCallback(async () => {
    setCategoryLoading(true);
    const result = await categoryItemSelected();
    if (result) {
      const data = result;
      const newOptions = data?.map((item) => ({
        value: item?.categoryItemId._id,
        label: item?.categoryItemId.name,
        backgroundColor: getRandomColor(),
      }));

      setOptions(newOptions);
    }
    setCategoryLoading(false);
  }, [categoryItemSelected]);
  useEffect(() => {
    if (guest === false && showModal) {
      FetchCategoryItemSelected();
    }
  }, [FetchCategoryItemSelected, guest, showModal]);

  //Private / Public  select
  const [isOn, setIsOn] = useState(false);
  const handleToggle = () => setIsOn(!isOn);

  //styling list items CSS
  function handleItemClick(index) {
    setSelectedItemIndex(index);
    setWishlistSelected(false); // Deselect wishlist
  }

  function handleWishlistClick() {
    setSelectedItemIndex(null); // Deselect other items
    setWishlistSelected(true); // Set wishlist as selected
    history.push("/wishlist");
  }
  function handleNavItemClick() {
    setSelectedItemIndex(null); // Deselect other items
    setWishlistSelected(false); // Deselect wishlist
  }

  const handleButtonClick = (value) => {
    const option = options.find((option) => option.value === value);
    if (!option) {
      return;
    }
    setValue("tags", [...tags, option]);

    if (title) {
      clearErrors("tags");
    }
    setOptions((prevOptions) =>
      prevOptions.filter((opt) => opt.value !== value)
    );
  };

  const handleSelectChange = (selectedOptions, second) => {
    setValue("tags", selectedOptions);
    setOptions((preValue) => [...preValue, second.removedValue]);
  };

  const onSubmit = async (data) => {
    let selectedTags = tags.map((item) => item.value);
    let newFormData = data;
    newFormData.title = data.title;
    newFormData.isPublic = isOn;
    newFormData.tags = selectedTags;

    const result = await CreateYourList(newFormData);
    if (result.status) {
      handleClose();
      fetchSidebarLists();
      setIsOn(false);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: (
      <NextArrow
        customStyle={{
          top: "-5%",
          right: "-5%",
          color: "#7a7a7a",
          border: "1px solid #7a7a7a",
          background: "#fff",
          borderRadius: "50%",
        }}
        buttonSize={32}
        slidesToShow={3}
        gradientStyle={{
          background:
            "linear-gradient(270deg, #FFF 69.79%, rgba(255, 255, 255, 0.00) 100%)",
          height: "100%",
          width: "15%",
          zIndex: 2,
          right: "-7%",
        }}
      />
    ),
    prevArrow: (
      <PreviousArrow
        customStyle={{
          top: "-5%",
          left: "-5%",
          color: "#7a7a7a",
          border: "1px solid #7a7a7a",
          borderRadius: "50%",
          backgroundColor: "#fff",
        }}
        gradientStyle={{
          background:
            "linear-gradient(90deg, #FFF 69.79%, rgba(255, 255, 255, 0.00) 100%)",
          width: "15%",
          height: "100%",
          zIndex: 2,
          left: "-7%",
        }}
        buttonSize={32}
      />
    ),
  };

  const activeCart = path.includes("/wardrobe/activeCart");
  const outfit = path.includes("/wardrobe/outfit");
  const purchase = path.includes("/wardrobe/purchased");

  useEffect(() => {
    if ((activeCart || outfit || purchase) && showRoom) {
      dispatch(openWardrobeRoomDrawer());
    } else {
      dispatch(closeWardrobeRoomDrawer());
    }
  }, [path]);

  return (
    <>
      <aside className={`pe-3 ${showRoom && "room-show"}`}>
        <PerfectScrollbar component="div" className="pt-4 pb-5">
          <Nav className="flex-column pe-4" style={{ gap: "38px" }}>
            <>
              {allowedRoutes.map(({ route, name, icon, children }, index) => (
                <Fragment key={`nav-item-${index}`}>
                  <NavItem
                    as={NavLink}
                    to={!guest && route}
                    className={`ps-4 ${showRoom && "justify-content-center"}`}
                    onClick={() =>
                      guest ? handleLoginModalOpen() : handleNavItemClick(index)
                    }
                  >
                    <div className="sidebar-items">
                      {icon}
                      {!isMobile && !showRoom ? <span>{name}</span> : null}
                    </div>
                  </NavItem>
                  {!isMobile && !showRoom && name === "Your Lists" && (
                    <>
                      {isOverflowing === true && (
                        <button
                          className="create-new my-n3"
                          onClick={() => guest ? handleLoginModalOpen() : handleShow()}
                        >
                          <p className="">New</p>
                        </button>
                      )}
                      <div className="your-lists-items-container">
                        <ul className="your-lists-items">
                          <li
                            className={wishlistSelected ? "your-lists-items__wishlist selected" : "your-lists-items__wishlist"}
                            onClick={() => guest ? handleLoginModalOpen() : handleWishlistClick()}
                          >
                            <span className="text-white">
                              <WishlistIcon />
                            </span>
                            <p>Wants</p>
                          </li>
                          {sidebarLists.length > 0 ? (
                            sidebarLists.map((item, index) => (
                              <li key={`list-${index}`} onClick={() => handleItemClick(index)}>
                                <span
                                  className={selectedItemIndex === index ? "lh-sm selected li-item text-truncate-2" : "lh-sm li-item text-truncate-2"}
                                  onClick={() => history.push(`/list-items/${item?.id}`, { data: item?.title })}
                                  style={{ display: "-webkit-box", width: "8em" }}
                                >
                                  {item?.title}
                                </span>
                              </li>
                            ))
                          ) : (
                            <li>No lists available</li>
                          )}
                        </ul>
                      </div>
                      {isOverflowing === false && (
                        <button
                          className="create-new"
                          onClick={() => guest ? handleLoginModalOpen() : handleShow()}
                        >
                          <p>New</p>
                        </button>
                      )}
                    </>
                  )}
                </Fragment>
              ))}
            </>
          </Nav>
          <div className="disclosure-statement">
            <small>{DISCLOSURE_STATEMENT}</small>
          </div>
        </PerfectScrollbar>
      </aside>
      {showModal && (
        <Modal show={showModal} onHide={handleClose} centered>
          <Modal.Body className="w-100 mx-auto">
            <Card
              className="w-100 mx-auto "
              style={{ maxWidth: 490, padding: "2rem" }}
            >
              <Modal.Header className="w-100 justify-content-between  p-0  border-0">
                <Col xs={10}>
                  <p className="text-black-100 Arial-Bold fs-28 fw-700">
                    Create New List
                  </p>
                </Col>
                <Col xs={1} className="d-flex justify-content-start">
                  <button
                    onClick={handleClose}
                    className="border-0 w-25 bg-transparent"
                  >
                    <img
                      src={closeModal1}
                      alt="close-the-modal"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </button>
                </Col>
              </Modal.Header>
              <Card.Body className="w-100 p-0 mt-4">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col xs={12} className="mb-3">
                      <Form.Text className="form-label-text">
                        List Title
                        <span className="text-danger"> *</span>{" "}
                      </Form.Text>

                      <Input
                        type="text"
                        id="title"
                        name="title"
                        label="Enter title name"
                        containerClassName="mt-2 "
                        className="with-border"
                        {...register("title", { required: true })}
                        error={errors.title?.message}
                      />
                    </Col>

                    {/* <Col xs={12} style={{ marginTop: "10px" }}>
                      <Form.Text className="form-label-text">
                        Tags
                      </Form.Text>
                      <Controller
                        control={control}
                        name="tags"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <ReactSelect
                            value={value}
                            onChange={(value, second) => {
                              handleSelectChange(value, second);
                              onChange(value, second);
                            }}
                            options={options}
                            isMulti={true}
                            placeholder="Select Tag"
                            styles={customStylesSelect}
                            wrapperClassName="pb-1 mt-3 mb-4 w-100"
                            error={errors.tags?.message}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        )}
                      />

                      <p className="suggested-text fs-16 text-gray-550 Arial-Regular">
                        Suggested:
                      </p>
                      <div className="suggested-category-items-container position-relative  py-3">
                        {categoryLoading ? (
                          <Container className="d-flex justify-content-center align-items-center loader-container ">
                            <Spinner animation="border" variant="dark" />{" "}
                          </Container>
                        ) : !!options?.length ? (
                          <Slider {...settings}>
                            {options?.map((option) => (
                              <div
                                key={option?.value}
                                onClick={() => handleButtonClick(option?.value)}
                                className="suggested-tabs d-flex rounded-pill"
                              >
                                <span>{option?.label}</span>
                              </div>
                            ))}
                          </Slider>
                        ) : (
                          <p className="text-black-100 fs-14 fw-500">
                            No Category
                          </p>
                        )}
                      </div>
                    </Col> */}
                    <Col xs={12} className="isPublic-toggle my-4">
                      <p>Public</p>
                      <div
                        className={`toggle-switch ${isOn ? "on" : "off"}`}
                        onClick={() => handleToggle(!isOn)}
                      >
                        <div
                          className={`toggle-switch-circle ${isOn ? "on" : "off"
                            }`}
                        />
                      </div>
                    </Col>

                    <Col xs={12} className="py-3 position-relative">
                      <Button
                        type="submit"
                        size="md"
                        className={
                          isValid
                            ? "w-100 login-submit-btn-filled"
                            : "w-100 login-submit-btn"
                        }
                        disabled={fetching}
                      >
                        {!fetching && <span>Create New List</span>}
                        {fetching && <Spinner animation="border" />}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.profileData,
    list: state.user.list,
    guest: state.auth.guest,
    fetching: state.user.fetching,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getYourLists: (payload) => dispatch(performGetYourLists(payload)),
  CreateYourList: (payload) => dispatch(performCreateYourLists(payload)),
  categoryItemSelected: (payload) =>
    dispatch(performGetCategoryItemsSelected(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

const routes = [
  {
    key: Constants.ROLE_ATTRIBUTES.DASHBOARD,
    validateRole: false,
    route: "/dashboard",
    name: "Home",
    icon: <Home size={18} />,
  },
  {
    key: Constants.ROLE_ATTRIBUTES.EXPLORE,
    validateRole: false,
    route: "/explore",
    name: "Explore",
    icon: <FiCompass size={18} />,
  },
  {
    key: Constants.ROLE_ATTRIBUTES.WARDROBE,
    validateRole: false,
    route: "/wardrobe/purchased",
    name: "Closet",
    icon: <Wardrobe />,
  },
  {
    key: Constants.ROLE_ATTRIBUTES.YOURLISTS,
    validateRole: false,
    route: "/your-lists",
    name: "Your Lists",
    icon: <FaListUl size={18} />,
    children: [],
  },
];

const accountRoutes = [
  {
    key: Constants.ROLE_ATTRIBUTES.EDITPROFILE,
    validateRole: false,
    route: "/account/edit",
    name: "Edit Profile",
    icon: <FiUser size={18} />,
  },
  // {
  //   key: Constants.ROLE_ATTRIBUTES.ORDERS,
  //   validateRole: false,
  //   route: "/account/order-settings",
  //   name: "Orders Settings",
  //   icon: <BsBoxSeam size={18} style={{ transform: "scaleX(-1)" }} />,
  // },
  {
    key: Constants.ROLE_ATTRIBUTES.SECURITY,
    validateRole: false,
    route: "/account/security-settings",
    name: "Security",
    icon: <FiKey size={18} />,
  },
  // {
  //   key: Constants.ROLE_ATTRIBUTES.NOTIFICATIONS,
  //   icon: <Notification />,
  //   validateRole: false,
  //   route: "/account/notification-settings",
  //   name: "Notifications",
  // },
  // {
  //   key: Constants.ROLE_ATTRIBUTES.REFERRAL,
  //   validateRole: false,
  //   route: "/account/referral-settings",
  //   name: "Referral",
  //   icon: <ReferralIcon />,
  // },
];
const customStylesSelect = {
  control: (base, state) => ({
    ...base,
    border: "2px solid #000000 !important",
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
    background: "#F5F5F5 !important",
    color: "white !important",
    appearance: "none",
    padding: "0.2rem",
    borderRadius: "4px",
    fontSize: "14px",
    width: "100%",
    minHeight: "48px",
    outline: "none !important",
    fontWeight: "600",
    transition: "all 0.2s ease, letter-spacing 0s",
  }),

  valueContainer: (base) => ({
    ...base,
    gap: "0.3rem !important",
  }),

  multiValue: (base, { data }) => {
    return {
      ...base,
      backgroundColor: data.backgroundColor,
      borderRadius: "20px",
      padding: "3px 6px",
      alignItems: "center",
    };
  },
  multiValueLabel: (base) => ({
    ...base,
    fontFamily: "Arial-Bold",
    fontSize: "16px",
    lineHeight: "100%",
    fontWeight: "700",
    letterSpacing: " 0.005em",
    color: "#000000",
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: "#000000",
    cursor: "pointer",
    height: "24px",
    width: "24px",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#000000",
    },

    "& > svg": {
      width: "100%",
      height: "100%",
    },
  }),

  dropdownIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    display: "none",
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    display: "none",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    display: "none",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "gray",
  }),
};
