import { combineReducers } from "redux";

import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import accountReducer from "./accountReducer";
import userReducer from "./userReducer";

export default combineReducers({
  auth: authReducer,
  alert: alertReducer,
  account: accountReducer,
  user: userReducer,
});
