import { Divider, Menu, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

const DropdownMenu = ({ menu, style, className, icon, iconSize, name }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!menu.length) return false;

    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const renderMenuItem = (item, index) => {
    if (item.divider) return <Divider />;

    const elProps = {
      key: `menu-item-${index}`,
      disableRipple: true,
    };

    if (!item.containerElement && item.onClick) {
      elProps.onClick = item.onClick;
    }

    return (
      <MenuItem
        className="dropdown-menuitem"
        {...elProps}
        disabled={item.disabled || false}
      >
        <div
          className={`dropdown-menuitem-items ${
            item?.className && `${item.className}`
          }`}
        >
          {item.icon ? <>{item?.icon}</> : null}
          <span className="Arial-Regular item-name">{item.label}</span>
        </div>
      </MenuItem>
    );
  };

  return menu ? (
    <>
      <button
        onClick={handleClick}
        className="dropdown-menu-button"
        style={{ ...style.button }}
      >
        {name}
      </button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        className={className}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            mt: 1.2,
            position: "absolute",
            width: "max-content",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            borderRadius: "8px",
            boxShadow: "0px 5px 25px 2px rgba(255, 255, 255, 0.1)",
            display: "flex",
            flexDirection: "column",
            padding: "0px 14px 8px 14px",
            gap: "10px",
            background: "#000000",
            ...style.menu,
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
          ...style.transformOrigin,
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
          ...style.anchorOrigin,
        }}
      >
        {menu && menu.map((item, index) => renderMenuItem(item, index))}
      </Menu>
    </>
  ) : null;
};

DropdownMenu.propTypes = {
  menu: PropTypes.array.isRequired,
  style: PropTypes.object,
  icon: PropTypes.any,
  iconSize: PropTypes.oneOf(["", "small", "large"]),
};

DropdownMenu.defaultProps = {
  style: {},
  iconSize: "",
  className: "",
};

export default DropdownMenu;
