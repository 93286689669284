import { useGoogleLogin } from "@react-oauth/google";
import React, { useCallback, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import FacebookLogin from "react-facebook-login";
import { useForm } from "react-hook-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import closeModal1 from "../../../assets/images/closeModal1.svg";
import { ReactComponent as SocialLink1 } from "../../../assets/images/social/SocialLink1.svg";
import { ReactComponent as SocialLink2 } from "../../../assets/images/social/SocialLink2.svg";
import { ReactComponent as SocialLink3 } from "../../../assets/images/social/SocialLink3.svg";
import Input from "../../../components/Form/Input";
import {
  performEmailExistCheck,
  performForgotPassword,
  performLogin,
  performResendOTP,
  performSocialLogin,
} from "../../../redux/actionCreators/authCreators";
import {
  guestLoginReset,
  resetState,
} from "../../../redux/actions/authActions";
import { emailExistRules, loginRules } from "../../../services/validations";
import { closeLoginModal } from "../../../redux/actions/userActions";
import { isValidEmail } from "../../../utils";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import Constants from "../../../utils/constants";

const LoginModal = ({
  showModal,
  guestLoginReset,
  handleCloseModal,
  isAuthenticated,
  submitting,
  handleLogin,
  handleEmailExistCheck,
  handleForgotPassword,
  handleSocialLogin,
  handleResendOTP,
  reset,
  setSignupModalData,
  handleShowSignupModal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginModalOpen = useSelector((state) => state.user?.isLoginModalOpen);

  const [emailExist, setEmailExist] = useState(true);
  const [forgotPassword, setForgotPassword] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: loginRules,
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    register: register1,
    handleSubmit: handleEmailExist,
    formState: { errors: errors1, isValid: isValid1 },
  } = useForm({
    resolver: emailExistRules,
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  // Close LoginModal
  const onCloseLoginModal = () => {
    dispatch(closeLoginModal());
  };

  const loginRedirect = useCallback(
    async (step, result) => {
      guestLoginReset();

      if (step === "pending_email_verification") {
        if (result && result?.data?.user?.email !== null) {
          await handleResendOTP({
            email: result?.data?.user?.email,
            action: "signup",
          });
          history.push(`/verification`, { data: result?.data?.user?.email });
        }
      } else if (step === "pending_mobile_verification") {
        if (result && result?.data?.user?.mobile !== null) {
          await handleResendOTP({
            mobile: result?.data?.user?.mobile,
            action: "signup",
          });

          history.push(`/otpverification`, {
            data: result?.data?.user?.mobile,
          });
        }
      } else if (step === "step-1") {
        history.push({
          pathname: "/userinfo",
        });
      } else if (step === "completed") {
        history.push(`/dashboard`);
      }
    },
    [history]
  );

  // Social Logins
  const Googlelogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      if (codeResponse?.code) {
        const newdata = {
          type: "google",
          token: codeResponse?.code,
          action: "login",
        };
        const result = handleSocialLogin(newdata);
        if (result?.status) {
          loginRedirect(result?.data?.user?.onboardingStep, result);
        }
      }
    },
  });

  const FacebookloginAccess = async (response) => {
    if (response?.accessToken) {
      const newdata = {
        type: "facebook",
        token: response?.accessToken,
        action: "login",
      };
      const result = await handleSocialLogin(newdata);
      if (result?.status) {
        loginRedirect(result?.data?.user?.onboardingStep, result);
        handleCloseModal();
        onCloseLoginModal();
      }
    }
  };

  // Handle User Login
  const onSubmit = async (data) => {
    let submitDataLogin = {};
    const isValidNumber = isValidPhoneNumber(
      data.email,
      Constants.COUNTRY_CODE
    );
    if (isValidNumber) {
      submitDataLogin.mobile = parsePhoneNumber(
        data.email,
        Constants.COUNTRY_CODE
      ).number;
      submitDataLogin.password = data.password;
    } else if (isValidEmail(data.email)) {
      submitDataLogin = data;
    } else {
      submitDataLogin.username = data.email;
      submitDataLogin.password = data.password;
    }
    const result = await handleLogin(submitDataLogin);
    if (result?.status) {
      loginRedirect(result?.data?.user?.onboardingStep, result);
      handleCloseModal();
      onCloseLoginModal();
    }
  };

  // Handle Check Email Exist
  const onSubmitEmailExist = async (data) => {
    let submitData = {};
    const isValidNumber = isValidPhoneNumber(
      data.email,
      Constants.COUNTRY_CODE
    );
    if (isValidNumber) {
      submitData.mobile = parsePhoneNumber(
        data.email,
        Constants.COUNTRY_CODE
      ).number;
      submitData.password = data.password;
    } else if (isValidEmail(data.email)) {
      submitData = data;
    } else {
      submitData.username = data.email;
      submitData.password = data.password;
    }
    setForgotPassword(submitData);
    const result = await handleEmailExistCheck({
      value: submitData.email || submitData.mobile || submitData.username,
    });
    if (!result?.isExists) {
      handleShowSignupModal(submitData);
      setSignupModalData(submitData);
      onCloseLoginModal();
    } else {
      setEmailExist(false);
      setValue("email", data.email);
    }
  };

  // Handle Submit Forgot Password
  const onSubmitForgotPassword = async (e) => {
    e.stopPropagation();
    let newData = forgotPassword?.email
      ? forgotPassword.email
      : forgotPassword?.username
      ? forgotPassword.username
      : forgotPassword.mobile;

    let submitForgotPasswordData = {};
    const isValidNumber = isValidPhoneNumber(newData, Constants.COUNTRY_CODE);

    if (isValidNumber) {
      submitForgotPasswordData.mobile = parsePhoneNumber(
        newData,
        Constants.COUNTRY_CODE
      ).number;
    } else if (isValidEmail(newData)) {
      submitForgotPasswordData.email = newData;
    } else {
      submitForgotPasswordData.username = newData;
    }

    const result = await handleForgotPassword(submitForgotPasswordData);
    if (result?.status) {
      guestLoginReset({ isAuthenticated: false });
      history.push(`/forgot-password`, {
        data: {
          email: forgotPassword?.email || result?.data?.email,
          mobile: forgotPassword?.mobile || result?.data?.mobile,
          username: forgotPassword?.username,
        },
      });
    }
  };

  // useEffect(() => {
  //   reset();
  // }, [reset]);

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Modal show={loginModalOpen} onHide={onCloseLoginModal} centered>
        <Modal.Body className="w-100 mx-auto">
          <div className="d-flex flex-column align-items-center h-100 justify-content-center py-5 px-4 login_form">
            <Card
              className="w-100 position-relative mx-auto px-4 pt-3 pb-4  "
              style={{ maxWidth: 450 }}
            >
              <button
                onClick={onCloseLoginModal}
                className="border-0 position-absolute end-0 top-0 me-4 mt-3 bg-transparent"
              >
                <img
                  src={closeModal1}
                  width={20}
                  height={20}
                  alt="close-the-modal"
                />
              </button>
              <Modal.Header className="w-100 justify-content-center pb-0 mt-3 border-0 mb-3">
                <p className="Arial-Bold fw-500">
                  Welcome {emailExist ? "to Cheeky" : "Back"}
                </p>
              </Modal.Header>
              <Card.Body className="p-0 my-4">
                {emailExist && (
                  <Form onSubmit={handleEmailExist(onSubmitEmailExist)}>
                    <Row>
                      <Col xs={12} className="">
                        <Form.Text className="form-label-text">
                          Username, Email or Phone Number
                        </Form.Text>
                        <Input
                          type="text"
                          id="email"
                          name="email"
                          containerClassName="mt-2"
                          label="Enter username, email or phone number"
                          {...register1("email", { required: true })}
                          error={errors1.email?.message}
                        />
                      </Col>
                      <Col xs={12} className="pt-3 mt-3 position-relative">
                        <Button
                          type="submit"
                          size="md"
                          className={
                            isValid1
                              ? "w-100 login-submit-btn-filled"
                              : "w-100 login-submit-btn"
                          }
                          disabled={submitting}
                        >
                          {!submitting && <span>Continue</span>}
                          {submitting && <Spinner animation="border" />}
                        </Button>
                      </Col>
                      <Col xs={12} className="position-relative">
                        <div className="d-flex justify-content-center mt-4">
                          <span className="social-login-icon">
                            <FacebookLogin
                              appId="233499206070944"
                              autoLoad={false}
                              fields="name,email,picture"
                              callback={FacebookloginAccess}
                              cssClass="my-facebook-button-class"
                              icon={<SocialLink1 />}
                            />
                          </span>
                          <span
                            className="social-login-icon"
                            onClick={() => Googlelogin()}
                          >
                            <SocialLink2 className="w-100 h-100" />
                          </span>
                          <span className="social-login-icon">
                            <SocialLink3 className="w-100 h-100" />
                          </span>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                          <span className="text-gray-520 fw-500">
                            Don’t have an account?
                          </span>{" "}
                          <div
                            className="sign-up-text pointer"
                            onClick={() => {
                              handleShowSignupModal();
                              handleCloseModal();
                              onCloseLoginModal();
                            }}
                          >
                            &nbsp;Sign Up
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
                {!emailExist && (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col xs={12} className="mb-3">
                        <Form.Text className="form-label-text">
                          Username, Email or Phone Number
                        </Form.Text>
                        <Input
                          type="text"
                          id="email"
                          name="email"
                          disabled={true}
                          containerClassName="mt-3"
                          label="Enter username, email or phone number"
                          {...register("email", { required: true })}
                          error={errors.email?.message}
                        />
                      </Col>

                      <Col xs={12} className="mb-3">
                        <Form.Text className="form-label-text">
                          Password
                        </Form.Text>
                        <Input
                          id="password"
                          type="password"
                          name="password"
                          label="Enter password"
                          containerClassName="mt-2"
                          {...register("password", { required: true })}
                          error={errors.password?.message}
                        />
                      </Col>

                      <Col xs={12}>
                        <p
                          className="mb-2 d-flex justify-content-end forget-password-text"
                          onClick={(e) => {
                            onSubmitForgotPassword(e);
                          }}
                        >
                          <span className="Arial-Regular">Forgot password</span>
                        </p>
                      </Col>

                      <Col xs={12} className="pt-2 position-relative">
                        <Button
                          type="submit"
                          size="md"
                          className={
                            isValid
                              ? "w-100 login-submit-btn-filled"
                              : "w-100 login-submit-btn"
                          }
                          disabled={submitting}
                        >
                          {!submitting && <span>Continue</span>}
                          {submitting && <Spinner animation="border" />}
                        </Button>
                      </Col>
                      <Col xs={12} className="position-relative">
                        <div className="d-flex justify-content-center mt-4">
                          <span className="social-login-icon">
                            <FacebookLogin
                              appId="233499206070944"
                              autoLoad={false}
                              fields="name,email,picture"
                              callback={FacebookloginAccess}
                              cssClass="my-facebook-button-class"
                              icon={<SocialLink1 />}
                            />
                          </span>
                          <span
                            className="social-login-icon"
                            onClick={() => Googlelogin()}
                          >
                            <SocialLink2 className="w-100 h-100" />
                          </span>
                          {/* <span className="social-login-icon">
                            <SocialLink3 className="w-100 h-100" />
                          </span>{" "} */}
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                          <span className="text-gray-520 fw-500">
                            Don’t have an account?
                          </span>
                          <div
                            className="sign-up-text pointer"
                            onClick={(e) => {
                              handleShowSignupModal();
                              handleCloseModal();
                              onCloseLoginModal();
                            }}
                          >
                            &nbsp;Sign Up
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.auth.submitting,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  reset: () => dispatch(resetState()),
  handleLogin: (payload) => dispatch(performLogin(payload)),
  guestLoginReset: (payload) => dispatch(guestLoginReset(payload)),

  handleEmailExistCheck: (payload) => dispatch(performEmailExistCheck(payload)),
  handleForgotPassword: (payload) => dispatch(performForgotPassword(payload)),
  handleSocialLogin: (payload) => dispatch(performSocialLogin(payload)),
  handleResendOTP: (payload) => dispatch(performResendOTP(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
