import React from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

import { performLogout } from "../../redux/actionCreators/authCreators";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DefaultLayout = ({ children, user, handleLogout, refreshToken }) => {
  const history = useHistory();
  return (
    <div className="w-100 layout--default" id="layoutDefault">
      <Header
        title="Dashboard"
        user={user}
        handleLogout={() => {
          handleLogout({ refresh_token: refreshToken });
          if (history.location.pathname === "/dashboard") {
            history.push("/account");
          }
        }}
      />
      <Sidebar />
      {children}
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  refreshToken: state.auth?.token?.refresh?.token,
});

const mapDispatchToProps = (dispatch) => ({
  handleLogout: (payload) => dispatch(performLogout(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
